import React, { useState, useEffect, useContext } from "react";
import { isMobile, isMobileOnly, isTablet } from "react-device-detect";
import confirmemail from "../assets/picture3.jpeg";
import thankyou from "../assets/thankyou.svg";
import { UserContext } from "../UserContext";
import '../App.css'
import { useHistory } from "react-router";
interface Props {
  token: any;
}
const ThankyouToVerify: React.FC<Props> = (props) => {
  const { toggle, settoggle } = useContext(UserContext)
  const [timeleft, settimeleft] = useState<any>()
  const history = useHistory()
    const intervalId = setInterval(() => {
      settoggle(true);
      localStorage.setItem('showSwipeHomeView', "true")
      history.push('/home')
    }, 5000);
    // return () => clearInterval(intervalId);
  return (
    <div className="container" style={{ height: isMobileOnly ? "40rem" : isTablet ? "52rem" : "", marginTop: isMobileOnly ? "" : "", padding : 0, }}>
      <div>
        {isMobileOnly ? (
          <div className="centered">
            <img
              width="100%"
              height="268px"
              style={{}}
              className="under"
              src={confirmemail}
            />
          </div>
        ) : (
          <div className="centered">
            <img
              width="700px"
              height="400px"
              style={{ marginTop : "-40px"}}
              className="under"
              src={confirmemail}
            />
          </div>
        )
        }
      </div>
      { isMobileOnly ? (
        <div
          style={{
            color: "#FAFAFA",
            fontSize: isMobileOnly ? "24px" : "40px",
            lineHeight: "30px",
            fontWeight: 500,
            marginTop: isMobileOnly ? "" : "3rem",
            fontFamily: "Atten New",
            fontStyle: "normal",
          }}
        >
          Thank you
        </div>
      ) : (
        <div
          style={{
            color: "#FAFAFA",
            fontSize: isMobileOnly ? "24px" : "40px",
            lineHeight: "30px",
            fontWeight: 500,
            marginTop: isMobileOnly ? "4rem" : "1rem",
            fontFamily: "Atten New",
            fontStyle: "normal",
          }}
        >
          Thank you
        </div>
      )
      }
      <div
        style={{
          fontWeight: isMobileOnly ? 400 : 500,
          color: "#C5C5C5",
          fontSize: "18px",
          lineHeight: "35px",
          marginTop: "0.5rem" ,
          fontFamily: "Atten New",
          fontStyle: "normal",
        }}
      >
        Your email has been verified successfully.
      </div>
    </div>
  );
};
export default ThankyouToVerify;
