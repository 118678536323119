import { memo } from "react";
import "./TotalsView.css";
import totalsImage from "../../images/totals_bg.svg";
import totalsImageMobile from "../../images/totals_bg_mobile.svg";
import { isMobile } from "react-device-detect";

interface TotalsViewProps {
  seatsSold?: String | null;
  commission?: String | null;
  payment?: String | null;
}

let styles = {
  totalsView: {
    height: isMobile ? "78px" : "140px",
  },
  numbLabel: {
    fontSize: isMobile ? "20px" : "50px",
  },
  titleLabel: {
    fontSize: isMobile ? "11px" : "16px",
  },
};
function thousands_separators(num: any) {
  var num_parts = num.toString().split(".");
  num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return num_parts.join(".");
}

function TotalsView(props: TotalsViewProps) {
  let seatsSold = thousands_separators(props.seatsSold);
  let commission = thousands_separators(props.commission);
  let payment = thousands_separators(props.payment);
  return (
    <div
      className="TotalsView position-relative flex-center"
      style={styles.totalsView}
    >
      <img
        className="position-absolute w-100 h-100"
        src={isMobile ? totalsImageMobile : totalsImage}
        alt=""
      />
      <div
        className={
          "contents w-100 position-relstive flex-center justify-content-around"
        }
      >
        <div className="total-content-div flex-center flex-column flex-grow-1">
          <div className="number-label" style={styles.numbLabel}>
            {seatsSold || "-"}
          </div>
          <div className="title-label" style={styles.titleLabel}>
            Seats Sold
          </div>
        </div>
        <div
          className="separator"
          style={{ height: isMobile ? "50px" : "100px" }}
        />
        <div className={`total-content-div flex-center flex-column ${isMobile ? "m-3" : "m-5"}`}>
          <div className="number-label" style={styles.numbLabel}>
            {commission || "-"}
          </div>
          <div className="title-label" style={styles.titleLabel}>
            Sales After Commission
          </div>
        </div>
        <div
          className="separator"
          style={{ height: isMobile ? "50px" : "100px" }}
        />
        <div className="total-content-div flex-center flex-column flex-grow-1">
          <div className="number-label" style={styles.numbLabel}>
            {payment || "-"}
          </div>
          <div className="title-label" style={styles.titleLabel}>
            Payments Made
          </div>
        </div>
      </div>
    </div>
  );
}

export default memo(TotalsView);
