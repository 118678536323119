import { useAuth0 } from "@auth0/auth0-react";
import React, { useContext } from "react";
import {
  Route,
  Redirect,
  RouteComponentProps,
  RouteChildrenProps,
  RouteProps,
} from "react-router-dom";
import { useVanillaAuth } from "../hooks/useVanillaAuth";
import { AuthContext } from "../UserContext";

interface PrivateRouteProps extends Omit<RouteProps, "component"> {
  component: React.ElementType;
  // any additional vars
}

export const ProtectRoute: React.FC<PrivateRouteProps> = ({
  component: Component,
  children,
  ...rest
}) => {
  const { isAuthenticated, user, loading } = useVanillaAuth();
  const { Auth, setAuth } = useContext(AuthContext);
  if (loading) {
    return null;
  }
  return (
    <Route
      {...rest}
      render={(routeProps) =>
        isAuthenticated && !loading? (
          <Component {...routeProps} />
        ) : Auth !== undefined ? (
          <Component {...routeProps} />
        ) : (
          <Redirect
            to={{
              pathname: "/",
              state: { from: routeProps.location },
            }}
          />
        )
      }
    />
  );
};
