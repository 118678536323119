import React, {useState, useEffect, useContext, Dispatch} from 'react'
import {makeStyles, createStyles, Theme} from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import {Grid} from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Picture from '../images/picture.png'
import Logo from '../images/tiqassist_logo.svg'
import BackgroundMobile from '../images/Background.png'
import {MobileView, isMobile, isMobileOnly} from 'react-device-detect'
import {Redirect, useHistory} from 'react-router'
import {DataContext, hideData, loginContext} from '../UserContext'
import {useDispatch, useSelector} from 'react-redux'
import {Link} from 'react-router-dom'
import {useAuth0} from '@auth0/auth0-react'
import LoginBackground from '../images/mls.png'
import Cookies from 'js-cookie'
import {ENV} from '../utils/environmentConfig'
import {useVanillaAuth} from '../hooks/useVanillaAuth'

const useStyles = makeStyles((theme) => ({
	root: {
		height: '100%',
		background: '#010101',
	},
	image: {
		height: '812px',
		width: '900px',
	},
	paper: {
		margin: theme.spacing(8, 4),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		marginTop: isMobile ? '' : '30px',
	},

	form: {
		marginTop: theme.spacing(1),
	},
	submitLogin: {
		margin: theme.spacing(3, 0, 2),
		width: isMobile ? '300px' : '406.8px',
		height: isMobile ? '44px;' : '78.36px',
		background: '#C57A1E',
		borderRadius: ' 50px',
		marginLeft: isMobile ? '5px' : '15px',
	},
	submitStart: {
		width: isMobile ? '300px' : '406.8px',
		height: isMobile ? '44px;' : '78.36px',
		border: '2px solid #C57A1E',
		boxSizing: 'border-box',
		borderRadius: ' 50px',
		marginLeft: isMobile ? '5px' : '15px',
	},
	heroContainer: {
		display: 'flex',
		backgroundImage: isMobileOnly ? `url(${BackgroundMobile})` : '',
		backgroundPosition: 'center',
		borderRadius: isMobile ? '4px' : '10px',
		background: '#010101',
		backgroundRepeat: 'no-repeat',
		backgroundSize: 'cover',
		width: '100%',
		height: '100vh',
	},
}))

export default function SignInSide() {
	// const {loginWithRedirect} = useAuth0()
	const loginWithRedirect = () => history.push('/login')
	const {user, isAuthenticated} = useVanillaAuth()
	const classes = useStyles()
	const {Hide, setHideData} = useContext(hideData)
	const dispatch: Dispatch<any> = useDispatch()
	const {trackData, setTrackData} = useContext(DataContext)
	const history = useHistory()
	const handleStart = () => {
		history.push('/start')
	}
	const [isSE, setSE] = useState(window.innerWidth < 321)
	const [is8Plus, set8Plus] = useState(
		window.innerWidth < 415 && window.innerWidth > 413
	)
	const [isIphone8, setIphone8] = useState(
		window.innerHeight < 668 && window.innerHeight > 666
	)

	const updateMedia = () => {
		setSE(window.innerWidth < 321)
		set8Plus(window.innerWidth < 415 && window.innerWidth > 413)
	}
	useEffect(() => {
		window.addEventListener('resize', updateMedia)
		return () => window.removeEventListener('resize', updateMedia)
	}, [])

	const {login, setlogin} = useContext(loginContext)

	useEffect(() => {
		console.log(isAuthenticated)
		if (isAuthenticated) {
			history.push('/home')
		}

		setTrackData([])
	}, [isAuthenticated])

	useEffect(() => {
		setlogin(user)
	}, [login])
	const BackgroundHead = {
		backgroundImage: 'url(' + BackgroundMobile + ')',
	}
	return (
		<Grid container className={classes.root}>
			<Grid
				item
				xs={12}
				sm={8}
				md={4}
				component={Paper}
				elevation={6}
				className={classes.heroContainer}
			>
				<div
					className={classes.paper}
					style={{marginBottom: isSE ? '0px' : isIphone8 ? '0px' : ''}}
				>
					{isSE ? (
						<img
							className='mt-5'
							src={Logo}
							style={{
								width: '222px',
								marginRight: '32px',
							}}
						/>
					) : (
						<img
							src={Logo}
							className={isMobileOnly ? '' : 'mt-5'}
							style={{
								width: isMobile ? '222px' : '292px',
								marginRight: isMobile ? '85px' : '95px',
							}}
						/>
					)}
					<div />
					<div style={{width: isMobileOnly ? '100%' : ''}}>
						{is8Plus ? (
							<div
								style={{
									textAlign: 'start',
									position: 'absolute',
									fontFamily: 'Atten New',
									fontStyle: 'normal',
									fontWeight: 800,
									fontSize: '33px',
									lineHeight: '113%',
									color: '#BC936B',
									marginTop: '10rem',
									marginLeft: '2rem',
								}}
								className='mb-5'
							>
								SEASON TICKETS <br />
								MADE SIMPLE.
							</div>
						) : isSE ? (
							<div
								style={{
									textAlign: 'start',
									position: 'absolute',
									fontFamily: 'Atten New',
									fontStyle: 'normal',
									fontWeight: 800,
									fontSize: '33px',
									lineHeight: '113%',
									color: '#BC936B',
									marginTop: '5rem',
									marginLeft: '0rem',
								}}
								className='mb-5'
							>
								SEASON TICKETS <br />
								MADE SIMPLE.
							</div>
						) : isMobileOnly ? (
							<div
								style={{
									textAlign: 'start',
									position: 'absolute',
									fontFamily: 'Atten New',
									fontStyle: 'normal',
									fontWeight: 800,
									fontSize: '33px',
									lineHeight: '113%',
									color: '#BC936B',
									marginTop: '10rem',
									marginLeft: '10px',
								}}
								className='mb-5'
							>
								SEASON TICKETS <br />
								MADE SIMPLE.
							</div>
						) : (
							<div
								style={{
									textAlign: 'start',
									position: 'absolute',
									fontFamily: 'Atten New',
									fontStyle: 'normal',
									fontWeight: 800,
									fontSize: isMobile ? '33px' : '45px',
									lineHeight: isMobile ? '113%' : '97%',
									color: '#BC936B',
									marginTop: isSE ? '7rem' : '6.5rem',
									marginLeft: isMobile ? '3px' : '-15px',
								}}
								className='mb-5'
							>
								SEASON TICKETS <br />
								MADE SIMPLE.
							</div>
						)}
						{isSE ? (
							<div
								style={{
									color: ' #FFFFFF',
									height: '26px',
									fontFamily: 'Atten New',
									fontStyle: 'normal',
									fontWeight: 'normal',
									fontSize: '22px',
									lineHeight: '26px',
									marginLeft: '2px',
									textAlign: 'initial',
									marginTop: '10.5rem',
								}}
								className='mb-5  '
							>
								Sell your seats in seconds
							</div>
						) : is8Plus ? (
							<div
								style={{
									color: ' #FFFFFF',
									height: '26px',
									fontFamily: 'Atten New',
									fontStyle: 'normal',
									fontWeight: 'normal',
									fontSize: '22px',
									lineHeight: '26px',
									marginLeft: '2rem',
									textAlign: 'initial',
									marginTop: '15.5rem',
								}}
								className='mb-5  '
							>
								Sell your seats in seconds
							</div>
						) : isMobileOnly ? (
							<div
								style={{
									color: ' #FFFFFF',
									height: '26px',
									fontFamily: 'Atten New',
									fontStyle: 'normal',
									fontWeight: 'normal',
									fontSize: '22px',
									lineHeight: '26px',
									marginLeft: '10px',
									textAlign: 'initial',
									marginTop: '15.5rem',
								}}
								className='mb-5  '
							>
								Sell your seats in seconds
							</div>
						) : (
							<div
								style={{
									color: ' #FFFFFF',
									height: '26px',
									fontFamily: 'Atten New',
									fontStyle: 'normal',
									fontWeight: 'normal',
									fontSize: isMobile ? '22px' : '33px',
									lineHeight: isMobile ? '26px' : '43px',
									marginLeft: isMobile ? '5px' : '-12px',
									textAlign: 'initial',
									marginTop: isMobileOnly ? '15.5rem' : '13.5rem',
								}}
								className='mb-5  '
							>
								Sell your seats in seconds
							</div>
						)}
					</div>
					{isSE ? (
						<form className={classes.form} noValidate>
							<Button
								type='submit'
								fullWidth
								variant='contained'
								onClick={() => {
									loginWithRedirect()
								}}
								className={classes.submitLogin}
								style={{
									marginTop: '0rem',
									fontFamily: 'Atten New',
									fontStyle: 'normal',
									fontWeight: 'bold',
									fontSize: '16px',
									lineHeight: '19px',
									background: '#C57A1E',
									color: '#FFFFFF',
									height: '44px',
									width: '260px',
								}}
							>
								Login
							</Button>
							{
								<Button
									type='submit'
									fullWidth
									variant='contained'
									onClick={handleStart}
									className={classes.submitStart}
									style={{
										width: '260px',
										height: '44px',
										fontFamily: 'Atten New',
										fontStyle: 'normal',
										fontSize: '16px',
										lineHeight: '19px',
										fontWeight: 'bold',
										color: '#C57A1E',
										backgroundColor: 'transparent',
									}}
								>
									Register
								</Button>
							}
						</form>
					) : isIphone8 ? (
						<form className={classes.form} noValidate>
							<Button
								type='submit'
								fullWidth
								variant='contained'
								onClick={() => {
									loginWithRedirect()
								}}
								className={classes.submitLogin}
								style={{
									marginTop: '3rem',
									fontFamily: 'Atten New',
									fontStyle: 'normal',
									fontWeight: 'bold',
									fontSize: '16px',
									lineHeight: '19px',
									background: ' #C57A1E',
									color: ' #FFFFFF',
									height: '44px',
									width: '305px',
								}}
							>
								Login
							</Button>
							{
								<Button
									type='submit'
									fullWidth
									variant='contained'
									onClick={handleStart}
									className={classes.submitStart}
									style={{
										width: '305px',
										height: '44px',
										marginTop: '0rem',
										fontFamily: 'Atten New',
										fontStyle: 'normal',
										fontSize: '16px',
										lineHeight: '19px',
										fontWeight: 'bold',
										color: '#C57A1E',
										backgroundColor: 'transparent',
									}}
								>
									Register
								</Button>
							}
						</form>
					) : isMobile ? (
						<form className={classes.form} noValidate>
							<Button
								type='submit'
								fullWidth
								variant='contained'
								onClick={() => {
									loginWithRedirect()
								}}
								className={classes.submitLogin}
								style={{
									marginTop: '5rem',
									fontFamily: 'Atten New',
									fontStyle: 'normal',
									fontWeight: 'bold',
									fontSize: '16px',
									lineHeight: '19px',
									background: '#C57A1E',
									color: ' #FFFFFF',
									height: '44px',
									width: '305px',
								}}
							>
								Login
							</Button>

							{
								<Button
									type='submit'
									fullWidth
									variant='contained'
									onClick={handleStart}
									className={classes.submitStart}
									style={{
										width: '305px',
										height: '44px',
										marginTop: '0rem',
										fontFamily: 'Atten New',
										fontStyle: 'normal',
										fontSize: '16px',
										lineHeight: '19px',
										fontWeight: 'bold',
										color: '#C57A1E',
										backgroundColor: 'transparent',
									}}
								>
									Register
								</Button>
							}
						</form>
					) : (
						<form className={classes.form} noValidate>
							{
								<Button
									type='submit'
									fullWidth
									variant='contained'
									onClick={handleStart}
									className={classes.submitStart}
									style={{
										position: 'absolute',
										marginTop: isMobile ? '5rem' : '10.5rem',
										fontFamily: 'Atten New',
										fontStyle: 'normal',
										fontWeight: 'bold',
										fontSize: isMobile ? '16px' : ' 22px',
										lineHeight: isMobile ? '19px' : '26px',
										background: '#010101',
										color: ' #C57A1E',
										height: isMobile ? '44px' : '60px',
										marginRight: isMobile ? '' : '15px',
										width: isMobile ? '305px' : '360px',
									}}
								>
									Register
								</Button>
							}

							<Button
								type='submit'
								fullWidth
								onClick={() => {
									loginWithRedirect()
								}}
								variant='contained'
								className={classes.submitLogin}
								style={{
									fontFamily: 'Atten New',
									fontStyle: 'normal',
									fontWeight: 'bold',
									fontSize: isMobile ? '16px' : ' 22px',
									lineHeight: isMobile ? '19px' : '26px',
									color: '#FFFFFF',
									height: '60px',
									marginRight: '15px',
									marginTop: '5.5rem',
									width: '360px',
								}}
							>
								Login
							</Button>
						</form>
					)}
				</div>
			</Grid>

			<Grid item xs={false} sm={4} md={8}>
				{!isMobileOnly ? (
					<img width='1010px' height='720vh' src={Picture} loading='lazy' />
				) : null}
			</Grid>
		</Grid>
	)
}
