import {SeatSetsActionTypes, form, STORE_SEATSETS, REMOVE_SEATSETS, UPDATE_SEATSETS} from './types'

export function addSeatSets(data: form): SeatSetsActionTypes {
  return {
    type: STORE_SEATSETS,
    payload: data,
  }
}

export function updateSeatSets(data: form, id: number): SeatSetsActionTypes {
  return {
    type: UPDATE_SEATSETS,
    payload: data,
    id: id,
  }
}

export function removeSeatSets(id: number): SeatSetsActionTypes {
  return {
    type: REMOVE_SEATSETS,
    meta: id,
  }
}
