import React, {useEffect} from 'react'
import {BrowserRouter as Router, Route, Switch, RouteComponentProps} from 'react-router-dom'
import SignInSide from './components/Login'
import StepperRoute from './StepperRoutes'
import RouteDashboard from './components/RouteDashboard'
import {useAuth0} from '@auth0/auth0-react'
import axios from 'axios'
import LoginView from './screens/login-screens/LoginView'
import {useVanillaAuth} from './hooks/useVanillaAuth'
import ReactGA from 'react-ga'
interface RouteProps {
	component?: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>
	render?: (props: RouteComponentProps<any>) => React.ReactNode
	path?: string | string[]
	exact?: boolean
	sensitive?: boolean
	strict?: boolean
}

let REACT_APP_GOOGLE_ANALYTICS_ID = process.env.REACT_APP_GOOGLE_ANALYTICS_ID

const trackingId = REACT_APP_GOOGLE_ANALYTICS_ID || ''

// ReactGA.initialize(trackingId)
ReactGA.initialize(trackingId)

const Routes = () => {
	const {isAuthenticated, user} = useVanillaAuth()

	useEffect(() => {
		console.log('~environment trackingId', trackingId)

		if (REACT_APP_GOOGLE_ANALYTICS_ID) {
			let userId = user['https://tiqassist.com/user_id']

			ReactGA.set({
				userId: userId || 'no-user',
				// any data that is relevant to the user session
				// that you would like to track with google analytics
			})
			ReactGA.pageview(window.location.pathname)
		}
	}, [])

	//
	//
	//Added the upper code for google analytics.
	//
	//

	return (
		<Router>
			<Switch>
				<Route exact path='/login' component={LoginView} />
				<Route exact path='/' component={SignInSide} />
				{isAuthenticated ? <RouteDashboard /> : <StepperRoute />}
			</Switch>
		</Router>
	)
}

export default Routes
