import React, {useEffect, useContext} from 'react'
import {activeTabContext} from '../UserContext'
let {log} = console

export const localStorageGetLastTab = () => Number(window.localStorage.getItem('lastTab')) || 0
export const localStorageSetLastTab = (val: number) => window.localStorage.setItem('lastTab', String(val))

const primaryColor = '#c57a1e'

export const TabVanilla = ({label, i}: {label: string; i: number}) => {
  const {active, setActive} = useContext(activeTabContext)
  // const history = useHistory()

  useEffect(() => {
    return () => log(`${label} unmounted...`)
  }, [])

  const onClick = () => {
    setActive(i)
    localStorageSetLastTab(i)
  }

  // I am using it here, just becoz useHistory hook has to be called only a child of <Router> component only, and thus this seems a good point to have this code here in our current example.
  // useEffect(() => {
  //   history.push(`/${active}`)
  // }, [active, history])

  const left = () => {
    // return "auto";
    if (i === active) {
      return '0'
    } else if (i < active) {
      return '100px'
    } else if (i > active) {
      return '-100px'
    } else {
      return 'auto'
    }
  }

  return (
    <div className='tab' style={{}}>
      <div
        style={{
          color: active === i ? primaryColor : '#E7E1BA',
          margin: '20px 20px 5px',
        }}
        onClick={onClick}>
        {label}
      </div>
      <div
        className={'underline'}
        style={{
          position: 'relative',

          // width: '100px',
          /* ^^ 🤺︎🤺︎ Since giving static widh to the highlight to the underline is bad user experience.*/
          borderBottomStyle: 'solid',
          borderBottomColor: primaryColor,
          borderBottomWidth: active === i ? '4px' : '0px',
          left: left(),
          transition: 'left .5s ease, width 1s ease', // left 1s ease,
          // todo💝︎: Add transition to the ``width`` as well, tip: add make a width function like you have for ``left`` and then set width somehow so that width is expanded with animation as well.(HINT: Use ``left 5s ease`` to test the width property animation in future.)
        }}></div>
      {/* index: {i} */}
    </div>
  )
}
