import React, {Dispatch, useContext, useEffect, useState} from 'react'
import Vector from '../assets/Vector.png'
import {isMobile, isMobileOnly, isTablet} from 'react-device-detect'
import {Row, Col, Container} from 'react-bootstrap'
import Button from '@material-ui/core/Button'
import cross from '../images/Cross.svg'
import Forward from '../assets/forward.png'
import Backward from '../assets/backward.png'
import {useSelector} from 'react-redux'
import {RootState} from '../store'
import moment from 'moment'
import Cross from '../assets/editt.svg'
import axios from 'axios'
import swal from 'sweetalert'
import line from '../assets/line.svg'
import {AuthContext, DataContext, UpdateForm, verifytoggle} from '../UserContext'
import {useHistory} from 'react-router'
import {Spring} from 'react-spring'
import {Item} from 'react-bootstrap/lib/Breadcrumb'
import {ScrollTo} from 'react-scroll-to'
import {API_END_POINT} from '../utils/Constant'
import {CircularProgress} from 'material-ui'
import {ENV} from '../utils/environmentConfig'

interface Data {
	id: number
	title: string
	Subtitle: string
}
interface props {
	activeStep: number
	handleReset(): void
	handleNext(value: any): void
	handleBack(value: React.BaseSyntheticEvent): void
	doubleBack(): void
	backhandler(value: any): void
	handletoggle(value: any): void
}

interface Id {
	id: string
}
interface Seats {
	section: string
	row: string
	lowseat: number
	quantity: number
	faceValue: number
	gamesId: Id[]
}
let l = console.log
const VerifyResaleTicketMobile: React.FC<props> = (props) => {
	const [seats, setseats] = useState<Seats[]>()
	const {trackData, setTrackData} = useContext(DataContext)
	const {updateform, setupdateform} = useContext(UpdateForm)
	const {verify, setverify} = useContext(verifytoggle)
	const [loading, setloading] = useState(false)
	const {Auth, setAuth} = useContext(AuthContext)
	const history = useHistory()
	const [currentIndex, setcurrentIndex] = useState<any>()
	const [Open, setOpen] = useState<boolean>(false)
	let seatsets = useSelector((state: RootState) => {
		return state.seatsets
	})
	const [finalState, setfinalState] = useState<any>(seatsets)

	const scrollEventCallback = (e: any) => {
		// document.documentElement.scrollTop = 0 // this will scroll to top.
		l('~sahil scrolleventhandler', document.documentElement.scrollTop) // 304 is search bar makes the document scroll value changes to.
		const scrollPosition = document.documentElement.scrollTop
		if (scrollPosition < 100) {
			// boom
			const el: any = document.querySelector('.back-to-top-button')
			if (!el) {
				return
			}
			el.style.visibility = 'hidden'
		} else if (scrollPosition > 100) {
			const el: any = document.querySelector('.back-to-top-button')
			if (!el) {
				return
			}
			el.style.visibility = 'visible'
		}
	}

	useEffect(() => {
		window.addEventListener('scroll', scrollEventCallback)
		return () => window.removeEventListener('scroll', scrollEventCallback)
	})

	let credential = useSelector((state: RootState) => {
		return state.credential
	})
	const HandleBack = () => {
		setTrackData({})
		props.backhandler(2)
		history.go(-2)
		// setActiveStep((prevActiveStep : number) => prevActiveStep - 2);
	}

	const HandleDelete = (val: any, va: any) => {
		console.log(val, va)
		var arr = [...finalState.seatSets]
		console.log(arr)

		let arr1 = arr.splice(
			arr.findIndex((a) => a.id === va.id),
			1
		)
		console.log(arr1)
		let arr2 = arr.filter((a) => {
			return a.id !== va.id
		})
		console.log(arr2)
		let a = arr1[0].gamesID.filter((a: any) => {
			return a.gameId !== val.gameId
		})
		arr1[0].gamesID = a
		console.log(arr1)
		arr2.push(arr1[0])
		console.log(arr2)
		setfinalState({seatSets: arr2})

		// let sets = finalState.seatSets.filter((value: any) => {
		//   return value.id === va.id;
		// });
		// let s = sets.map((s: any) =>
		//   s.gamesID.filter((f: any) => {
		//     return f.gameId !== val.gameId;
		//   })
		// );
		// let ss = finalState.seatSets.map((se: any) => {
		//   if (se.id === va.id) {
		//     return (se.gamesID = s[0]);
		//   }
		// });
	}
	console.log(finalState)
	async function HandleSubmit() {
		setloading(true)
		let seatSets = seatsets.seatSets.map((item) => {
			return {
				section: item.section,
				row: item.row,
				lowSeat: parseInt(item.fromSeat),
				quantity: parseInt(item.toSeat) - parseInt(item.fromSeat) + 1,
				faceValue: parseInt(item.price),
				gamesId: item.gamesID.map((v) => {
					return v.gameId
				}),
				teamId: item.teams.selectedTeam[0].sky_id,
			}
		})

		let data = {
			name: credential.name,
			email: credential.email,
			seatSets: seatSets,
		}
		console.log(JSON.stringify(data, null, 2))

		const url = `${process.env.REACT_APP_ROOT_URL}${API_END_POINT.SENDCODE}`

		axios({
			method: 'post',
			url: url,
			headers: {
				'Content-Type': 'application/json',
			},
			data: data,
		})
			.then((res) => {
				console.log(res)
				if (res.status === 200) {
					console.log(res)
					setAuth(res.data)
					localStorage.setItem('provisionalClientId', res.data.provisClientId)
					props.handleNext(4)
					setloading(false)
					history.push('confirm-mail')
				}
			})
			.catch((err) => {
				console.log(err)
			})
		console.log(data)
	}
	console.log(trackData)
	console.log(finalState)
	return (
		<div className='container'>
			<div
				className='label'
				style={{
					textAlign: 'center',
					marginLeft: '',
					fontSize: isTablet ? '40px' : '21px',
					fontWeight: 700,
					color: '#BC936C',
					fontFamily: 'Atten New',
					marginTop: '1rem',
					marginBottom: '1rem',
				}}
			>
				Confirm your season ticket package
			</div>
			{finalState.seatSets.length > 0 ? (
				finalState.seatSets.map((val: any, index: any) => {
					return (
						<>
							{loading ? (
								<div
									className='loading-screen-listing-delisting'
									style={{
										// background: 'grey',
										position: 'absolute',
										width: '100vw',
										height: '100vh',
										top: 0,
										left: 0,
										zIndex: 5000,
										background: 'rgba(0, 0, 0, 0.9)',
									}}
								>
									<div
										style={{
											position: 'absolute',
											// border: '2px solid green',
										}}
									>
										<CircularProgress color='#A39C73' />
										<br />
										<div
											style={{
												// top: 20,
												padding: 20,
												color: '#BC936B',
												position: 'relative',
												fontSize: '1.7rem',
											}}
										>
											Check your email ...
										</div>
									</div>
								</div>
							) : (
								''
							)}

							<Row
								className='col-12'
								style={{
									paddingTop: '5%',
									paddingBottom: '25px',
									fontWeight: 'bold',
									marginLeft: 'initial',
									paddingLeft: 0,
								}}
							>
								<Col style={{paddingLeft: '8px'}}>
									<div
										style={{
											textAlign: 'initial',
											fontFamily: 'Roboto',
											fontWeight: 500,
											fontSize: '18px',
										}}
									>
										<div
											style={{
												float: 'right',
											}}
											// onClick={() => { props.handletoggle(false) }}
										>
											<img
												style={{marginRight: '-8px', cursor: 'pointer'}}
												src={Cross}
												onClick={() => {
													setTrackData({
														id: val.id,
														teams: {
															selectedTeam: val.teams.selectedTeam,
														},
														fromSeat: val.fromSeat,
														gamesID: val.gamesID,
														row: val.row,
														section: val.section,
														toSeat: val.toSeat,
														price: val.price,
													})
													setupdateform(true)
													props.doubleBack()
													history.go(-1)
												}}
											/>
										</div>
									</div>
									<div
										style={{
											fontSize: '14.12px',
											color: '#BC936C',
											textAlign: 'initial',
											fontFamily: 'Atten New',
											fontWeight: 400,
										}}
									>
										{val.gamesID.length > 1 ? <span>{val.gamesID.length} games</span> : <span>{val.gamesID.length} game</span>}
									</div>
									<div
										style={{
											color: '#FFFFFF',
											textAlign: 'initial',
											fontFamily: 'Atten New',
											fontWeight: 400,
										}}
									>
										{val.teams.selectedTeam[0].home_team}
										{Open === true ? (
											<img
												style={{marginLeft: '5%', marginBottom: '2%'}}
												src={Forward}
												onClick={() => {
													currentIndex === index ? setcurrentIndex(null) : setcurrentIndex(index)

													setOpen(!Open)
												}}
											/>
										) : (
											<img
												style={{marginLeft: '5%', marginBottom: '2%'}}
												src={Backward}
												onClick={() => {
													currentIndex === index ? setcurrentIndex(null) : setcurrentIndex(index)

													setOpen(!Open)
												}}
											/>
										)}
									</div>
									<Row style={{marginTop: '6%'}}>
										<Col className='col-3'>
											<div
												style={{
													color: '#E7E1C5',
													fontFamily: 'Atten New',
													fontWeight: 400,
													fontSize: '14.12px',
												}}
											>
												Section
											</div>
											<div
												style={{
													fontFamily: 'Atten New',
													fontWeight: 400,
													color: '#FFFFFF',
													fontSize: '14.12px',
												}}
											>
												{val.section}
											</div>
										</Col>
										<Col className='col-3'>
											<div
												style={{
													fontFamily: 'Atten New',
													fontWeight: 400,
													fontSize: '14.12px',
													color: '#E7E1C5',
												}}
											>
												Row
											</div>
											<div
												style={{
													fontFamily: 'Atten New',
													fontWeight: 400,
													color: '#FFFFFF',
													fontSize: '14.12px',
												}}
											>
												{val.row}
											</div>
										</Col>
										<Col className='col-3' style={{paddingRight: 0}}>
											<div
												style={{
													fontFamily: 'Atten New',
													fontWeight: 400,
													fontSize: '14.12px',
													color: '#E7E1C5',
												}}
											>
												Seats
											</div>
											<div
												style={{
													fontFamily: 'Atten New',
													fontWeight: 400,
													color: '#FFFFFF',
													fontSize: '14.12px',
												}}
											>
												{val.fromSeat}-{val.toSeat}
											</div>
										</Col>
										<Col className='col-3'>
											<div
												style={{
													fontFamily: 'Atten New',
													fontWeight: 400,
													fontSize: '14.12px',
													color: '#E7E1C5',
												}}
											>
												Avg Face Value
											</div>
											<div
												style={{
													fontFamily: 'Atten New',
													fontWeight: 400,
													color: '#FFFFFF',
													fontSize: '14.12px',
												}}
											>
												${val.price}
											</div>
										</Col>
									</Row>
								</Col>
							</Row>
							{currentIndex === index && (
								<Spring from={{opacity: 0}} to={{opacity: 1}}>
									{(props) => (
										<>
											{Open === true && window.pageYOffset > 300 ? (
												<ScrollTo>
													{({scroll}) => (
														<button
															className='back-to-top-button'
															style={{
																position: 'fixed',
																width: '98px',
																height: 36,
																borderRadius: 50,
																// marginTop: '10px',
																boxShadow: 'none',
																backgroundColor: '#C57A1E',
																color: '#FFFFFF',
																fontSize: '12px',
																// marginLeft: '-55px',
																// bottom: '25px',
																top: '25px',
																left: '39vw',
																zIndex: 1,
															}}
															onClick={() => scroll({y: 0})}
														>
															Back to top
														</button>
													)}
												</ScrollTo>
											) : null}
											<div style={props as React.CSSProperties | undefined}>
												{val.gamesID.map((v: any, i: any) => {
													console.log(i)
													return (
														<>
															<Row
																className='col-12'
																style={{
																	paddingTop: '4%',
																	paddingBottom: '0px',
																	fontWeight: 'bold',
																	marginLeft: 'initial',
																	paddingLeft: 0,
																}}
															>
																<Col style={{paddingLeft: '8px'}}>
																	<div
																		style={{
																			textAlign: 'initial',
																			fontFamily: 'Roboto',
																			fontWeight: 500,
																			fontSize: '18px',
																		}}
																	>
																		<div
																			style={{
																				float: 'right',
																			}}
																			// onClick={() => { props.handletoggle(false) }}
																		>
																			<img
																				style={{
																					marginRight: '-8px',
																					cursor: 'pointer',
																				}}
																				src={cross}
																				onClick={() => {
																					HandleDelete(v, val)
																				}}
																			/>
																		</div>
																	</div>
																	<div
																		style={{
																			fontSize: '14.12px',
																			color: '#BC936C',
																			textAlign: 'initial',
																			fontFamily: 'Atten New',
																			fontWeight: 400,
																		}}
																	>
																		{moment(v.datetime).format('llll')}
																	</div>
																	<div
																		style={{
																			color: '#FFFFFF',
																			textAlign: 'initial',
																			fontFamily: 'Atten New',
																			fontWeight: 400,
																		}}
																	>
																		{
																			/* {v.description.slice(
                                        0,
                                        v.description.length -
                                          val.teams.selectedTeam[0].home_team.length -
                                          3
                                      )} */
																			v.description
																		}
																	</div>
																	<Row style={{marginTop: '6%'}}>
																		<Col className='col-3'>
																			<div
																				style={{
																					color: '#E7E1C5',
																					fontFamily: 'Atten New',
																					fontWeight: 400,
																					fontSize: '14.12px',
																				}}
																			></div>
																			<div
																				style={{
																					fontFamily: 'Atten New',
																					fontWeight: 400,
																					color: '#FFFFFF',
																					fontSize: '14.12px',
																				}}
																			></div>
																		</Col>
																		<Col className='col-3'>
																			<div
																				style={{
																					fontFamily: 'Atten New',
																					fontWeight: 400,
																					fontSize: '14.12px',
																					color: '#E7E1C5',
																				}}
																			></div>
																			<div
																				style={{
																					fontFamily: 'Atten New',
																					fontWeight: 400,
																					color: '#FFFFFF',
																					fontSize: '14.12px',
																				}}
																			></div>
																		</Col>
																		<Col className='col-3' style={{paddingRight: 0}}>
																			<div
																				style={{
																					fontFamily: 'Atten New',
																					fontWeight: 400,
																					fontSize: '14.12px',
																					color: '#E7E1C5',
																				}}
																			></div>
																			<div
																				style={{
																					fontFamily: 'Atten New',
																					fontWeight: 400,
																					color: '#FFFFFF',
																					fontSize: '14.12px',
																				}}
																			></div>
																		</Col>
																		<Col className='col-3'>
																			<div
																				style={{
																					fontFamily: 'Atten New',
																					fontWeight: 400,
																					fontSize: '14.12px',
																					color: '#E7E1C5',
																				}}
																			></div>
																			<div
																				style={{
																					fontFamily: 'Atten New',
																					fontWeight: 400,
																					color: '#FFFFFF',
																					fontSize: '14.12px',
																				}}
																			></div>
																		</Col>
																	</Row>
																</Col>
															</Row>
															<>{i === val.gamesID.length - 1 ? null : <img src={line} />}</>

															{/* {Open === true &&
                                val.gamesID.length === i + 1 &&
                                val.gamesID.length > 10 ? (
                                <ScrollTo>
                                  {({ scroll }) => (
                                    <button
                                      style={{
                                        width: "98px",
                                        height: 36,
                                        borderRadius: 50,
                                        marginTop: "10px",
                                        boxShadow: "none",
                                        backgroundColor: "#C57A1E",
                                        color: "#FFFFFF",
                                        fontSize: "12px    ",
                                      }}
                                      onClick={() => scroll({ y: 0 })}
                                    >
                                      Back to top
                                    </button>
                                  )}
                                </ScrollTo>
                              ) : null} */}
														</>
													)
												})}
											</div>
										</>
									)}
								</Spring>
							)}
							<>
								<img src={line} />
							</>
						</>
					)
				})
			) : (
				<div
					style={{
						color: '#E7E1C5',
					}}
				>
					Add More Seats or Teams
					{/* Please select the " ADD MORE SEATS OR TEAMS" button to continue. */}
					{/* ^^^ This is the notification text when there is 0 teams so user can be notified to add teams with this message. */}
				</div>
			)}
			<br />

			{/* <Row
        className="col-12"
        style={{
          borderBottomStyle: "solid",
          paddingTop: "5%",
          borderBottomColor: "#cdd0dd",
          paddingBottom: "4%",
          fontWeight: "bold",
          borderBottomWidth: "1px",
          marginLeft: "initial",
          paddingLeft: 0,
        }}
      >
        <Col>
          <div
            style={{
              color: "#cdd0dd",
              textAlign: "initial",
              fontFamily: "Roboto",
              fontWeight: 500,
            }}
          >
            41 games
          </div>

          <div
            style={{
              textAlign: "initial",
              fontFamily: "Roboto",
              fontWeight: 500,
              fontSize: "18px",
            }}
          >
            Chicago Cubs
            <div
              style={{
                float: "right",
              }}
            >
              <img style={{ marginRight: "-8px" }} src={Vector} />
            </div>
          </div>

          <Row style={{ marginTop: "6%" }}>
            <Col className="col-3">
              <div
                style={{
                  color: "#cdd0dd",
                  fontFamily: "Roboto",
                  fontWeight: 500,
                }}
              >
                Section
              </div>
              <div style={{ fontFamily: "Roboto", fontWeight: 500 }}>218</div>
            </Col>
            <Col className="col-3">
              <div
                style={{
                  fontFamily: "Roboto",
                  fontWeight: 500,
                  color: "#cdd0dd",
                }}
              >
                Row
              </div>
              <div style={{ fontFamily: "Roboto", fontWeight: 500 }}>12</div>
            </Col>
            <Col className="col-3" style={{ paddingRight: 0 }}>
              <div
                style={{
                  fontFamily: "Roboto",
                  fontWeight: 500,
                  color: "#cdd0dd",
                }}
              >
                Seats
              </div>
              <div style={{ fontFamily: "Roboto", fontWeight: 500 }}>15-19</div>
            </Col>
            <Col className="col-3">
              <div
                style={{
                  fontFamily: "Roboto",
                  fontWeight: 500,
                  color: "#cdd0dd",
                }}
              >
                Values
              </div>
              <div style={{ fontFamily: "Roboto", fontWeight: 500 }}>
                217.35
              </div>
            </Col>
          </Row>
        </Col>
      </Row> */}
			<Row style={{marginTop: '8%'}}>
				{' '}
				<Col
					className='col-12'
					style={{
						paddingTop: isMobileOnly ? '5rem' : '35px',
						paddingRight: isMobileOnly ? '31px' : '',
						paddingLeft: isMobileOnly ? '31px' : '',
					}}
				>
					<Button
						style={{
							fontFamily: 'Atten New',
							color: finalState.seatSets.length === 0 ? 'rgb(252, 252, 252)' : '#BC936C',
							backgroundColor: finalState.seatSets.length === 0 ? 'rgb(197, 122, 30)' : '#010101',
							fontWeight: 400,
							borderRadius: 50,
							height: '50px',
							width: '100%',
							borderStyle: 'solid',
							borderWidth: '1px',
							borderColor: '#BC936C',
							fontSize: isMobile ? '14px' : '15px',
						}}
						onClick={() => {
							// setTrackData({})
							// props.doubleBack();
							// history.push('teams')
							HandleBack()
						}}
					>
						{finalState.seatSets.length === 0 ? 'Continue' : 'ADD MORE SEATS OR TEAMS'}
					</Button>
				</Col>
			</Row>
			<div>
				{isMobileOnly ? (
					<div>
						<Row
							style={{
								width: '100%',
								marginLeft: '1px',
								marginTop: '5%',
								marginBottom: '32px',
							}}
						>
							{' '}
							<Col>
								{finalState.seatSets.length !== 0 ? (
									<Button
										variant='contained'
										disabled={finalState.seatSets.length === 0 ? true : false}
										style={{
											width: '100%',
											height: 50,
											borderRadius: 50,
											boxShadow: 'none',
											// backgroundColor: finalState.seatSets.length === 0 ? '#5B5B5B' : '#C57A1E',
											backgroundColor: '#C57A1E',
										}}
										onClick={() => {
											HandleSubmit()
										}}
									>
										<div style={{color: '#FCFCFC'}}>NEXT</div>
									</Button>
								) : null}
							</Col>
						</Row>
					</div>
				) : (
					<div>
						<Row
							style={{
								width: '100%',
								marginLeft: '1px',
								marginTop: '10%',
								marginBottom: '35px',
							}}
						>
							{' '}
							<Col>
								<Button
									variant='contained'
									style={{
										width: '100%',
										height: 50,
										borderRadius: 50,
										boxShadow: 'none',
										backgroundColor: '#BC936C',
									}}
									onClick={() => {
										HandleSubmit()
									}}
								>
									<div style={{color: '#FCFCFC'}}>NEXT</div>
								</Button>
							</Col>
						</Row>
						<Row
							style={{
								marginTop: '6%',
								marginLeft: isMobileOnly ? '0px' : '',
								marginRight: isMobileOnly ? '0px' : '',
							}}
						>
							{' '}
							<Col>
								<Button
									variant='contained'
									disabled={true}
									style={{
										width: '100%',
										height: 50,
										borderRadius: 50,
										backgroundColor: '#BC936C',
										boxShadow: 'none',
									}}
									onClick={(e) => {
										props.handleBack(e)
									}}
								>
									<div style={{color: '#FAFAFA'}}>Back</div>
								</Button>
							</Col>
						</Row>
					</div>
				)}
			</div>
		</div>
	)
}
export default VerifyResaleTicketMobile
