import CircularProgress from 'material-ui/CircularProgress'

function Loader(props: any) {
	return (
		<div
			className='flex-center flex-column'
			style={{
				position: 'absolute',
				width: '100%',
				height: 'inherit',
				background: 'rgba(0, 0, 0, 0.7)',
			}}
		>
			<CircularProgress color='#A39C73' />
			<br />
			<div
				style={{
					padding: 10,
					color: '#BC936B',
					position: 'relative',
					fontSize: '1.7rem',
				}}
			>
				{props.text}
			</div>
		</div>
	)
}

export default Loader
