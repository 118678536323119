import React, {useState, useEffect, useContext} from 'react'
import Form from 'react-bootstrap/Form'
import * as yup from 'yup'
import Button from 'react-bootstrap/Button'
import {Formik, yupToFormErrors} from 'formik'
import {isMobile, isMobileOnly, isTablet} from 'react-device-detect'
import {Row, Col, Container} from 'react-bootstrap'
import {useSelector, useDispatch} from 'react-redux'
import {Dispatch} from 'redux'
import {addCredential} from '../store/credential/actions'
import {DataContext, myiconContext} from '../UserContext'
import {useHistory, useParams} from 'react-router'
import {RootState} from '../store/index'
import {checkEmailExist, registerStepRequest} from '../utils/ApiManager.js'
import swal from 'sweetalert'
import ReactGA from 'react-ga'

interface ButtonProps {
	activeStep: number
	handleBack(value: React.BaseSyntheticEvent): void
	handleNext(value: any): void
}

const SignUpSchema = yup.object().shape({
	Fullname: yup
		.string()
		.min(2, 'Too Short!')
		.max(50, 'Too Long!')
		.required('Required')
		.matches(/^[a-zA-Z]+ [a-zA-Z]+$/, 'Please Enter your Full Name'),
	Email: yup.string().email('Invalid email').required('Required'),
})

let l = console.log

const getLocalStorageState = () => {
	const data = JSON.parse(localStorage.getItem('registration-info') || '{}')
	return {Fullname: data.Fullname ?? '', Email: data.Email ?? ''}
}

const Start: React.FC<ButtonProps> = (props) => {
	const {trackData, setTrackData} = useContext(DataContext)
	const {Icon, setIcon} = useContext(myiconContext)
	const dispatch: Dispatch<any> = useDispatch()
	const history = useHistory()
	const [isIPadPro, setIPadPro] = useState(window.innerWidth < 1025)
	let par = useParams()
	const updateMedia = () => {
		setIPadPro(window.innerWidth < 1025)
	}

	let state2 = useSelector((state: RootState) => {
		return state.credential
	})

	let [localStorageState, setLocalStorageState] = useState(getLocalStorageState)

	l('~busy night', {state2})

	useEffect(() => {
		localStorage.setItem('registration-info', JSON.stringify(localStorageState))
	}, [localStorageState])

	useEffect(() => {
		window.addEventListener('resize', updateMedia)
		return () => window.removeEventListener('resize', updateMedia)
	})
	const [isIPadMini, setIPadMini] = useState(window.innerWidth < 769)
	const updateMediaMini = () => {
		setIPadMini(window.innerWidth < 769)
	}
	useEffect(() => {
		window.addEventListener('resize', updateMediaMini)
		return () => window.removeEventListener('resize', updateMediaMini)
	})

	return (
		<div
			className='container'
			style={{
				width: isMobile || isTablet ? '' : '76%',
				paddingLeft: isMobileOnly ? '12px' : '',
				marginTop: isMobileOnly ? '' : '',
				paddingRight: isMobile ? '' : '0px',
			}}
		>
			<Formik
				// initialValues={{Fullname: state2.name, Email: state2.email}} //~sahil, commented now...
				initialValues={{Fullname: localStorageState.Fullname, Email: localStorageState.Email}} //~sahil, added 21 june, 21.
				validationSchema={SignUpSchema}
				onSubmit={(data) => {
					console.log('~start.tsx incoming data in start.tsx', data) // ```data.Fullname, data.Email```.

					// alert('e1-name_email_submitted..')

					// ReactGA.event({
					// 	category: JSON.stringify({fullname: data.Fullname, email: data.Email}) || '_EMPTY_DATA',
					// 	action: 'registration-submit__name-and-email',
					// 	label: 'registration',
					// })
					// debugger
					registerStepRequest({name: data.Fullname, email: data.Email})

					const SUCCESS_CALLBACK = (resp: any) => {
						console.log('~start.tsx resp.response', resp.data.response)

						if (resp.data.response === true) {
							swal('Error!', 'User already exists ! \n Try logging in instead.', 'error')
							return // << That breaks the flow..
						}
						// If user exist already then just return back.

						const data1: any = {
							name: data.Fullname,
							email: data.Email,
						}
						dispatch(addCredential(data1))

						history.push('teams')
						setIcon([
							{
								active: false,
								completed: true,
								error: false,
								icon: 1,
							},
							{
								active: true,
								completed: false,
								error: false,
								icon: 2,
							},
							{
								active: false,
								completed: false,
								error: false,
								icon: 3,
							},
							{
								active: false,
								completed: false,
								error: false,
								icon: 4,
							},
						])
						console.log(Icon)
						props.handleNext(1)
					}

					const ERROR_CALLBACK = (err: any) => {
						alert(err)
					}

					checkEmailExist(data.Email, SUCCESS_CALLBACK, ERROR_CALLBACK)
				}}
			>
				{({values, handleChange, handleSubmit, handleBlur, errors, touched}) => (
					<Form onSubmit={handleSubmit} autoComplete={'off'}>
						<Row>
							<Col
								style={{
									marginLeft: isMobileOnly ? '3%' : isIPadMini ? '13%' : isIPadPro ? '9%' : '',
									marginRight: isMobileOnly ? '3%' : isIPadPro ? '7%' : isIPadMini ? '13%' : '',
								}}
							>
								<Form.Group controlId='Fullname' style={{marginTop: isMobile ? '8%' : ''}}>
									<Form.Label
										style={{
											color: 'white',
											float: 'left',
											fontWeight: isMobileOnly ? 400 : 700,
											fontFamily: 'Atten New',
											fontSize: isMobileOnly ? '16px' : '17px',
											lineHeight: isMobileOnly ? '19.2px' : '20.4px',
										}}
									>
										Full Name<span style={{color: 'red'}}>*</span>
									</Form.Label>
									<Form.Control
										type='text'
										placeholder='Enter your full name'
										value={values.Fullname}
										onChange={(e) => {
											handleChange(e) //for formik
											setLocalStorageState((state) => {
												return {...state, Fullname: e.target.value}
											})
											// we are storing values to localStorage using a useEffect hook with `localStorageState` as its dependency.
										}}
										onBlur={handleBlur}
										style={{
											height: isMobile ? '50px' : '65px',
											backgroundColor: '#151515',
											color: '#FAFAFA',
											border: '1px solid rgba(255, 255, 255, 0.14)',
										}}
									/>
									{errors.Fullname && touched.Fullname ? <div style={{color: 'red', fontSize: '12px', float: 'left'}}>{errors.Fullname}</div> : null}
								</Form.Group>
							</Col>
						</Row>
						<Row>
							<Col
								style={{
									marginLeft: isMobileOnly ? '3%' : isIPadMini ? '13%' : isIPadPro ? '9%' : '',
									marginRight: isMobileOnly ? '3%' : isIPadPro ? '7%' : isIPadMini ? '13%' : '',
									marginTop: isMobileOnly ? '7px' : '',
								}}
							>
								<Form.Group controlId='Email' style={{marginTop: isMobile ? '' : '2%'}}>
									<Form.Label
										style={{
											color: 'white',
											float: 'left',
											fontWeight: isMobileOnly ? 400 : 700,
											fontFamily: 'Atten New',
											fontSize: isMobileOnly ? '16px' : '17px',
											lineHeight: isMobileOnly ? '19.2px' : '20.4px',
										}}
									>
										Email<span style={{color: 'red'}}>*</span>
									</Form.Label>
									<Form.Control
										type='email'
										placeholder='Enter e-mail address'
										value={values.Email}
										onChange={(e) => {
											handleChange(e) //for formik
											setLocalStorageState((state) => {
												return {...state, Email: e.target.value}
											})
										}}
										onBlur={handleBlur}
										style={{
											height: isMobile ? '50px' : '65px',
											backgroundColor: '#151515',
											color: '#FAFAFA',
											border: '1px solid rgba(255, 255, 255, 0.14)',
										}}
									/>
									{errors.Email && touched.Email ? <div style={{color: 'red', fontSize: '12px', float: 'left'}}>{errors.Email}</div> : null}
								</Form.Group>
							</Col>
						</Row>

						<div>
							<div
								className={par !== 'start' ? '' : 'row'}
								style={{
									boxShadow: 'none',
									justifyContent: 'space-evenly',
									marginTop: isMobileOnly ? '35%' : '5rem',
									// marginBottom: "5%",
									paddingRight: '18px',
									paddingLeft: '18px',
								}}
							>
								<div>
									{par === 'start' && (
										<Button
											variant='contained'
											disabled={props.activeStep === 0}
											style={{
												width: 150,
												height: '100%',
												borderRadius: 50,
												backgroundColor: '#FAFAFA',
											}}
											onClick={(e) => {
												props.handleBack(e)
											}}
										>
											<div style={{color: '#FCFCFC'}}>BACK</div>
										</Button>
									)}
								</div>
								<div>
									<Button
										variant='contained'
										style={{
											width: isMobile ? '100%' : '200px',
											height: isMobile ? '50px' : '50px',
											borderRadius: 50,
											backgroundColor: '#C57A1E',
											fontFamily: isMobileOnly ? 'Atten new' : '',
											fontSize: isMobileOnly ? '14px' : '',
										}}
										onClick={() => {
											handleSubmit()
										}}
									>
										<div style={{color: '#FCFCFC'}}>{par !== 'start' ? 'CONTINUE' : 'NEXT'}</div>
									</Button>
								</div>
							</div>
						</div>
					</Form>
				)}
			</Formik>
		</div>
	)
}
export default Start
