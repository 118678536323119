import axios from 'axios'
import jwt from 'jsonwebtoken'
import {API_END_POINT} from './Constant'

async function callApi(url, method, headers, queryParams, bodyParams) {
	const bearer = await getBearerToken()
	const options = {
		method: method,
		url: url,
		params: {...queryParams},
		data: {...bodyParams},
		headers: {'Content-Type': 'application/json', ...headers},
	}
	return await axios
		.request(options)
		.then((resp) => {
			return resp
		})
		.catch((error) => {
			return error
		})
}

export function sendOtp(email, success, error) {
	let object = {
		client_id: process.env.REACT_APP_SDK_ID,
		client_secret: process.env.REACT_APP_CLIENT_SECRET,
		connection: 'email',
		email: email,
		send: 'code',
		authParams: {
			scope: 'offline_access',
		},
	}
	callApi('https://' + process.env.REACT_APP_OAUTH_DOMAIN + '/passwordless/start', 'POST', null, null, {...object})
		.then((resp) => {
			success && success(resp)
		})
		.catch((err) => {
			error && error(err)
		})
}

// let checkEmailUrl =
// 'https://6dvzj3ijy6.execute-api.us-east-1.amazonaws.com/dev/email_exist'

let checkEmailUrl = `${process.env.REACT_APP_ROOT_URL}/email_exist`

export function checkEmailExist(email, successCb, error) {
	callApi(checkEmailUrl, 'POST', {authorization: ''}, null, {email: email})
		.then((resp) => {
			successCb && successCb(resp)
		})
		.catch((err) => {
			error && error(err)
		})
}

export function dashboardCreated(obj, successCb, error) {
	let url = `${process.env.REACT_APP_ROOT_URL}/dashboard/${obj.id}`
	callApi(url, 'POST', null, null, {email: obj.email})
		.then((resp) => {
			successCb && successCb(resp)
		})
		.catch((err) => {
			error && error(err)
		})
}

export function loginWithOtp(details, success, error) {
	callApi('https://' + process.env.REACT_APP_OAUTH_DOMAIN + '/oauth/token', 'POST', null, null, {
		client_id: process.env.REACT_APP_SDK_ID,
		client_secret: process.env.REACT_APP_CLIENT_SECRET, // Paras added this in production as well # 23 June, 2021.
		// client_secret: process.env.REACT_APP_CLIENT_SECRET || 'lXkrPaFKm4VPebloIitxX8x5gKtmuMXb6tXmp3VmoGDmFy1_bh7JR6BSkeBIvolW',
		otp: details.otp,
		username: details.email,
		audience: `https://${process.env.REACT_APP_OAUTH_DOMAIN}/userinfo`,
		grant_type: 'http://auth0.com/oauth/grant-type/passwordless/otp',
		realm: 'email',
		scope: 'offline_access',
	})
		.then((resp) => {
			if (resp.status === 200) {
				// debugger // boom.
				localStorage.setItem('Auth0Data', JSON.stringify(resp.data))
				success && success(resp)
			} else {
				error && error({message: 'The OTP you entered is incorrect. Please enter the correct OTP'})
			}
		})
		.catch((err) => {
			console.log(JSON.stringify(null, 2, err))
			error && error(err)
			// alert('wrong otp code please check!')
		})
}

export async function getRefreshToken(refresh_token, success, error) {
	return callApi('https://' + process.env.REACT_APP_OAUTH_DOMAIN + '/oauth/token', 'POST', null, null, {
		client_id: process.env.REACT_APP_SDK_ID,
		client_secret: process.env.REACT_APP_CLIENT_SECRET,
		grant_type: 'refresh_token',
		refresh_token: refresh_token,
		scope: 'offline_access',
	})
		.then((resp) => {
			return resp.data
		})
		.catch((e) => {})
}

const getBearerToken = async () => {
	let AuthData = JSON.parse(localStorage.getItem('Auth0Data') || '{}') // DO NOT USE EMPTY STRING IN JSON PARSE!!
	let user = jwt.decode(AuthData.id_token) || {}
	const currentTime = () => Math.floor(new Date().getTime() / 1000)
	const isExpired = currentTime() > user.exp
	if (isExpired) {
		let newUser = await getRefreshToken(AuthData.refresh_token)

		if (newUser) {
			AuthData.id_token = newUser.id_token
			AuthData.access_token = newUser.access_token

			localStorage.setItem('Auth0Data', JSON.stringify(AuthData))
		}
	}

	return AuthData.id_token
}

export const registerStepRequest = async (bodyParams) => {
	const sendVerificationUrl = `${process.env.REACT_APP_ROOT_URL}/register`

	callApi(sendVerificationUrl, 'POST', null, null, bodyParams)
}
