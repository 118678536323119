import {useState} from 'react'
import {makeStyles} from '@material-ui/core/styles'
import MobileStepper from '@material-ui/core/MobileStepper'
import SwipeableViews from 'react-swipeable-views'
// import {autoPlay} from 'react-swipeable-views-utils'
import step1 from '../images/step1.svg'
import step2 from '../images/step2.svg'
import step3 from '../images/step3.svg'
import step4 from '../images/step4.svg'
import {isMobileOnly} from 'react-device-detect'
import './OnboardingStepper.css'
// const AutoPlaySwipeableViews = autoPlay(SwipeableViews)

const tutorialSteps = [
	{
		imgPath: step1,
		label: ' List your tickets at the schedule release.',
		Content: 'Resale prices generally are at their highest when the schedule is released, as it’s the perfect combination of limited supply and frenzied buyer demand.',
	},
	{
		imgPath: step2,
		label: 'If you can’t list at schedule release, list no later than one month ahead of the game.',
		Content: 'Many sellers list with two weeks to go, flooding the market with inventory.',
	},
	{
		imgPath: step3,
		label: 'If you share seats, make sure to get the tickets into your team account ASAP.',
		Content: 'That way TiqAssist can get the best sale prices for you. ',
	},
	{
		imgPath: step4,
		label: 'That way TiqAssist can get the best sale prices for you. ',
		Content: '',
	},
]

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
		background: 'transparent',
	},
	dot: {
		background: 'rgba(231, 225, 197, 0.2)',
	},
	dotActive: {
		background: '#BC936B',
	},
	button: {
		color: '#c8a178',
		fontWeight: 'bold',
		fontSize: '16px',
		fontFamily: 'Atten New',
	},
}))

interface SwipableViewProps {
	className: string
	skipTapped(): void
}

function SwipableView(props: SwipableViewProps) {
	const classes = useStyles()
	const [state, setstate] = useState({
		index: 0,
	})
	const handleNext = (index: number) => {
		if (index > tutorialSteps.length - 1) return
		setstate({...state, index: index})
	}
	return (
		<div className={`SwipeableView flex-center ${isMobileOnly ? 'mobile' : 'desktop'} ${props.className}`}>
			<div className='swipeable-content-box flex-center flex-column justify-content-around position-relative p-3'>
				<SwipeableViews index={state.index} className='w-100' enableMouseEvents onChangeIndex={(index) => handleNext(index)}>
					{tutorialSteps.map((value) => (
						<div className='flex-center flex-column' key={value.label}>
							<img src={value.imgPath} />
							<div className='title-label'>{value.label}</div>
							<div className='detail-label'>{value.Content}</div>
						</div>
					))}
				</SwipeableViews>
				<MobileStepper
					classes={{root: classes.root, dot: classes.dot, dotActive: classes.dotActive}}
					variant='dots'
					steps={tutorialSteps.length}
					position='static'
					activeStep={state.index}
					nextButton={
						<div className='action-button next' onClick={() => handleNext(state.index + 1)}>
							Next
						</div>
					}
					backButton={
						<div className='action-button skip' onClick={() => props.skipTapped()}>
							Skip
						</div>
					}
				/>
			</div>
		</div>
	)
}

export default SwipableView
