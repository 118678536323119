import { CredentialActionTypes, credentialState, REMOVE_CREDENTIAL, STORE_CREDENTIAL } from './types'

// TypeScript infers that this function is returning SendMessageAction
export function addCredential(data: credentialState): CredentialActionTypes {
  return {
    type: STORE_CREDENTIAL,
    payload: data
  }
}

// TypeScript infers that this function is returning DeleteMessageAction
export function removeCredential(timestamp: number): CredentialActionTypes {
  return {
    type: REMOVE_CREDENTIAL,
    meta: {
      timestamp
    }
  }
}