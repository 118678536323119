import React, {useContext, useEffect, useRef, useState} from 'react'
import {makeStyles, Theme} from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import axios, {AxiosResponse} from 'axios'
import publicIp from 'public-ip'
import {API_END_POINT, CONFIG_DETAILS, CONSTANT} from '../utils/Constant'
import {isMobile, isMobileOnly, isTablet} from 'react-device-detect'
import {Grid} from '@material-ui/core'
import Vector from '../assets/arrow.svg'
import {Card, Row, Col} from 'react-bootstrap'
import {AnyCnameRecord} from 'dns'
import {Spring} from 'react-spring'
import Button from 'react-bootstrap/Button'
import Search from './search'
import {Collapse} from 'react-bootstrap'
import {values} from 'lodash'
import {CircularProgress} from 'material-ui'
import {useSelector} from 'react-redux'
import {RootState} from '../store/index'
import {addSeatSets} from '../store/seatSets/action'
import {DataContext, focusContext} from '../UserContext'
import {useHistory} from 'react-router'
import {elementScrollIntoView} from 'seamless-scroll-polyfill'
import ReactGA from 'react-ga'
import {registerStepRequest} from '../utils/ApiManager'

interface TabPanelProps {
	children?: React.ReactNode
	index: any
	value: any
}
interface Props {
	activeStep: number
	handleBack(value: React.BaseSyntheticEvent): void
	handleNext(value: any): void
	handlerHide(value: any): void
}

interface detail {
	id: number
	player: string
}

interface Team {
	name: string
	Details: detail[]
}

interface Teams {
	City: string
	League: string
	State: string
	franchise: string
	home_team: string
	home_team_short: string
	sky_id: number
	leagueOrder: number
}

function TabPanel(props: TabPanelProps) {
	const {children, value, index, ...other} = props

	return (
		<div role='tabpanel' hidden={value !== index} id={`scrollable-auto-tabpanel-${index}`} aria-labelledby={`scrollable-auto-tab-${index}`} {...other}>
			{value === index && (
				<Box p={3}>
					<Typography>{children}</Typography>
				</Box>
			)}
		</div>
	)
}

function a11yProps(index: any) {
	return {
		id: `scrollable-auto-tab-${index}`,
		'aria-controls': `scrollable-auto-tabpanel-${index}`,
	}
}

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		flexGrow: 1,
		width: '100%',
		backgroundColor: theme.palette.background.paper,
	},
	indicator: {
		color: '0085FF',
	},
}))

let l = console.log

export default function TeamsMobile(props: Props) {
	const classes = useStyles()
	const [value, setValue] = React.useState(0)
	const {trackData, setTrackData} = useContext(DataContext)

	useEffect(() => {
		localStorage.setItem('registration-TeamsData', JSON.stringify(trackData))
		l('~sahil teamsmobile updated')
	}, [trackData])

	const history = useHistory()
	const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
		console.log(newValue)

		setValue(newValue)
	}
	let seatsets = useSelector((state: RootState) => {
		return state.seatsets.seatSets
	})
	let credential = useSelector((state: RootState) => {
		return state.credential
	})
	const [searchData, setSearchData] = useState<Teams[]>([])
	const [bool, setbool] = useState<boolean>()
	const [DataArray, setDataArray] = useState<Teams[]>([])
	const [TeamsData, setTeamsData] = useState<Teams[] | AxiosResponse>([])
	const [leaguearray, setleaguearray] = useState<string[]>([])
	const [More, SetMore] = useState(false)
	const [num, setnum] = useState<number>()
	const [region, setregion] = useState<string>('California')
	const {focus, setfocus} = useContext(focusContext)
	const [CopyData, setCopyData] = useState<Teams[]>([])
	const [currentIndex, setcurrentIndex] = useState<any>(null)
	const searchedText = useRef('')
	const [selectedTeams, setselectedTeams] = useState<Teams[]>(trackData.teams === undefined ? [] : trackData.teams.selectedTeam)
	const [local, setlocal] = useState<boolean>()
	const [isSE, setSE] = useState(window.innerWidth < 321)

	const [focused, setFocused] = React.useState(false)
	const onFocus = () => {
		setFocused(true)
		setfocus(true)
	}
	const onBlur = () => {
		setFocused(false)
		setfocus(false)
	}
	const messagesEndRef = useRef<null | HTMLDivElement>(null)

	const scrollToBottom = () => {
		messagesEndRef.current?.scrollIntoView({behavior: 'smooth'})
	}

	const updateMedia = () => {
		setSE(window.innerWidth < 321)
	}
	useEffect(() => {
		window.addEventListener('resize', updateMedia)
		return () => window.removeEventListener('resize', updateMedia)
	}, [])

	useEffect(() => {
		publicIp
			.v4()
			.then((ip) => {
				axios
					.get(`http://api.ipstack.com/${ip}?access_key=${CONFIG_DETAILS.API_KEY}`)
					.then((response) => {
						console.log(response)
						setregion(response.data.region_name)
					})
					.catch((err) => {
						console.log(err)
					})

				return
			})
			.catch((err) => {
				JSON.stringify(err)
			})
	}, [region])

	React.useEffect(() => {
		async function FetchData() {
			var bodyData = new FormData()
			let requestHeaders: any = {'Content-Type': 'multipart/form-data'}
			bodyData.append('name', '123')
			const url = 'https://6dvzj3ijy6.execute-api.us-east-1.amazonaws.com/dev/teams'
			// const url = `${process.env.REACT_APP_ROOT_URL}${API_END_POINT.TEAM}`
			const response = await axios({
				method: 'get',
				url: url,
				headers: requestHeaders,
				data: bodyData,
			})
			console.log(response)
			setTeamsData(response.data.sort())

			if (response.data.length === 0) {
				console.log(response)

				return <CircularProgress />
			} else {
				let arr = response.data
				console.log(arr)
				setDataArray(arr)
				setCopyData(arr)
				setSearchData(arr)

				if (DataArray.length === 0) {
					console.log(DataArray)
					return <CircularProgress />
				} else {
					console.log('data')
					const arr = DataArray.sort((a, b) => a.leagueOrder - b.leagueOrder)

					const unique = Array.from(new Set(arr.map((item) => item.League)))
					console.log(unique)
					setleaguearray(['Local Teams', ...unique])
					setbool(false)
				}
			}
		}
		FetchData()
		if (isMobile) {
			// to stop search field move to downwards while searching
			let e = Array.from(document.getElementsByClassName('container') as HTMLCollectionOf<HTMLElement>)[0]
			e.style.height = '1000px'
		}
	}, [CopyData.length === 0])

	// const handleSubmit = async () => {
	//   setTrackData({
	//     id: trackData.id !== undefined ? trackData.id : seatsets.length,
	//     teams: {
	//       selectedTeam: selectedTeams,
	//     },
	//     fromSeat: trackData.fromSeat !== undefined ? trackData.fromSeat : "",
	//     gamesID: trackData.gamesID !== undefined ? trackData.gamesID : [],
	//     row: trackData.row !== undefined ? trackData.row : "",
	//     section: trackData.section !== undefined ? trackData.section : "",
	//     toSeat: trackData.toSeat !== undefined ? trackData.toSeat : "",
	//     price: trackData.price !== undefined ? trackData.price : "",
	//   });
	//   props.handleNext(3);
	// };

	// useEffect(() => {
	//   if (isMobile) {
	//     // to stop search field move to downwards while searching
	//     let e = Array.from(
	//       document.getElementsByClassName(
	//         "search-div"
	//       ) as HTMLCollectionOf<HTMLElement>
	//     )[0];
	//     e.style.height = "1000px";
	//   }
	// }, []);

	const onSearch = async (search: string) => {
		console.log(search)
		// setbool(false);
		// if (search.length > 0) {
		//   setlocal(true);
		// }
		// if (
		//   DataArray.find((val) =>
		//     val.home_team.toLocaleLowerCase().includes(search)
		//   )
		// ) {
		//   setDataArray(
		//     DataArray.filter((element) =>
		//       element.home_team.toLocaleLowerCase().includes(search)
		//     )
		//   );
		//   const arr = DataArray.sort((a, b) => a.leagueOrder - b.leagueOrder);

		//   const unique = Array.from(new Set(arr.map((item) => item.League)));
		//   setleaguearray(["Local Teams", ...unique]);
		// }
		// if (search.length === 0) {
		//   const arr = DataArray.sort((a, b) => a.leagueOrder - b.leagueOrder);

		//   const unique = Array.from(new Set(arr.map((item) => item.League)));
		//   setleaguearray(["Local Teams", ...unique]);

		//   setbool(true);
		//   setlocal(false);
		//   setDataArray(searchData);
		// } else {
		//   setbool(false);
		// }

		let condition = new RegExp(search.toLocaleLowerCase())

		var result = await Promise.all(
			CopyData.filter(function (el: any) {
				return condition.test(el.home_team.toLocaleLowerCase())
			})
		)
		console.log('Result', result)

		searchedText.current = search
		setDataArray(result)
		if (search.length === 0) {
			setDataArray(DataArray)
		}
	}

	useEffect(() => {
		if (focused) {
			scrollToBottom()
		} else {
			const arr = DataArray.sort((a, b) => a.leagueOrder - b.leagueOrder)

			const unique = Array.from(new Set(arr.map((item) => item.League)))
			setleaguearray(['Local Teams', ...unique])
		}
	}, [focused])

	return (
		<>
			<div className='search-div' style={{marginTop: '5%', display: 'inline-block'}}>
				<Search
					onFocus={(e) => {
						if (isMobile) {
							let ele = document.getElementsByClassName('search-div')[0]
							elementScrollIntoView(ele, {
								behavior: 'smooth',
								block: 'start',
								inline: 'center',
							})
						}
					}}
					onBlur={(e) => {}}
					onSearch={onSearch}
				/>
			</div>
			<div
				className='container'
				style={{
					paddingLeft: isMobileOnly ? '0px' : '',
					paddingRight: isMobileOnly ? '0px' : '',
					marginTop: focused ? '-40px' : '',
				}}
			>
				{leaguearray.slice(0, 1).map((league: string, index) => {
					const arr1 = DataArray.filter((leg) => {
						return leg.State === region
					})
					return (
						<div className={classes.root} key={index} style={{backgroundColor: '#010101'}}>
							<AppBar position='static' color='transparent' elevation={0}>
								<Tabs
									value={value}
									onChange={handleChange}
									indicatorColor='primary'
									textColor='inherit'
									variant='scrollable'
									scrollButtons='auto'
									aria-label='scrollable auto tabs example'
									TabIndicatorProps={{
										style: {
											backgroundColor: '#C57A1E',
											borderBottomStyle: 'solid',
											borderBottomColor: '#c57a1e',
											borderBottomWidth: '5px',
										},
									}}
									classes={{
										indicator: classes.indicator,
									}}
								>
									{arr1.length > 0
										? leaguearray.map((league) => {
												console.log(leaguearray)

												return (
													<Tab
														label={league}
														style={{
															color: '#BC936C',
															fontSize: isMobileOnly ? '13px' : '18px',
															fontFamily: 'Atten New',
															fontWeight: 'bold',
														}}
														{...a11yProps}
													/>
												)
										  })
										: leaguearray.slice(0).map((league) => {
												console.log(leaguearray)

												return (
													<Tab
														label={league}
														style={{
															color: '#BC936C',
															fontSize: isMobileOnly ? '13px' : '18px',
															fontFamily: 'Atten New',
															fontWeight: 'bold',
															borderBottomStyle: 'solid',
															borderBottomColor: '#696969',
														}}
														{...a11yProps}
													/>
												)
										  })}
								</Tabs>
							</AppBar>

							{arr1.length > 0
								? leaguearray.map((val, i) => {
										if (val === 'Local Teams') {
											const arr = DataArray.filter((value) => value.State === region)
											console.log('{{LOCAL TEAMS}}')
											return (
												<TabPanel value={value} index={i} key={i}>
													<Row style={{marginTop: '1rem'}}>
														{arr1.length > 0
															? arr?.slice(0, arr.length).map((leg: any, index: any) => {
																	console.log(val)

																	return (
																		<Grid container item xs={6} md={2} sm={3} key={leg.sky_id}>
																			<Col
																				className='cardMobile'
																				key={leg.sky_id}
																				style={{
																					width: 150,
																					height: 110,
																					userSelect: 'none',
																					marginRight: '5px',
																					marginBottom: '10px',
																					borderColor: selectedTeams.findIndex((o) => o.sky_id === leg.sky_id) !== -1 ? '#BC936B' : '#2D2A2F',
																					backgroundColor: selectedTeams.findIndex((o) => o.sky_id === leg.sky_id) !== -1 ? '#1D1A1F' : '#151515',
																					display: 'flex',
																					wordBreak: 'break-word',
																				}}
																				onClick={() => {
																					// alert('boom1')
																					if (selectedTeams.findIndex((o) => o.sky_id === leg.sky_id) === -1) {
																						setselectedTeams([leg])
																						const desiredData1 = {
																							id: trackData.id !== undefined ? trackData.id : seatsets.length,
																							teams: {
																								selectedTeam: [leg],
																							},
																							fromSeat: trackData.fromSeat !== undefined ? trackData.fromSeat : '',
																							gamesID: trackData.gamesID !== undefined ? trackData.gamesID : [],
																							row: trackData.row !== undefined ? trackData.row : '',
																							section: trackData.section !== undefined ? trackData.section : '',
																							toSeat: trackData.toSeat !== undefined ? trackData.toSeat : '',
																							price: trackData.price !== undefined ? trackData.price : '',
																						}

																						// alert('e2-1-team_selected..')
																						// this is click handler for tab local team tab. ~sahil

																						// ReactGA.event({
																						// 	category: JSON.stringify(desiredData1) || '_EMPTY_DATA',
																						// 	action: 'registration-submit__team-selected',
																						// 	label: 'registration',
																						// })

																						const desiredArray = [desiredData1]

																						const registerStepData = {
																							name: credential.name,
																							email: credential.email,
																							seatSets: desiredArray.map((item) => {
																								return {
																									teamId: item.teams.selectedTeam[0].sky_id,
																								}
																							}),
																						}
																						console.log('~teamsmobile.tsx team-selected (local teams tab desiredData1', desiredData1)
																						console.log('~teamsmobile.tsx team-selected (local teams tab) stepregisterdata', registerStepData)
																						// debugger
																						registerStepRequest(registerStepData)

																						setTrackData(desiredData1)
																						history.push('seats')
																						props.handleNext(3)
																					} else {
																						let arr = selectedTeams.filter((o) => o.sky_id !== leg.sky_id)
																						setselectedTeams(arr)
																					}
																				}}
																			>
																				<div
																					className='card_name'
																					style={{
																						fontSize: '22px',
																						color: selectedTeams.findIndex((o) => o.sky_id === leg.sky_id) !== -1 ? '#BC936C' : '#FAFAFA',
																						fontWeight: 600,
																						fontFamily: 'Atten New !important',
																					}}
																				>
																					{arr.length === 0 ? <div>No Local Teams</div> : leg.home_team}
																				</div>
																			</Col>
																		</Grid>
																	)
															  })
															: null}
													</Row>
													{isTablet ? (
														<Row>
															{' '}
															<Col
																style={{
																	height: '2rem',
																	textAlign: 'right',
																	display: 'flex',
																	justifyContent: 'center',
																}}
															>
																<div>
																	{More ? 'Show Less' : 'Show More'} &nbsp;
																	<img
																		src={Vector}
																		onClick={() => {
																			SetMore(!More)
																		}}
																		style={{cursor: 'pointer'}}
																	/>
																</div>
															</Col>
														</Row>
													) : null}
												</TabPanel>
											)
										} else {
											const arr = DataArray.filter((value) => value.League === val)
											return (
												<TabPanel value={value} index={i}>
													<Row style={{marginTop: '1rem'}}>
														{arr?.slice(0, arr.length).map((leg: any, index: any) => {
															console.log(val)

															return (
																<Grid container item xs={6} md={2} sm={3}>
																	<Col
																		className='cardMobile'
																		key={leg.sky_id}
																		style={{
																			width: 150,
																			height: 110,
																			userSelect: 'none',
																			marginRight: '5px',
																			marginBottom: '10px',
																			display: 'flex',
																			borderColor: selectedTeams.findIndex((o) => o.sky_id === leg.sky_id) !== -1 ? '#BC936B' : '#2D2A2F',
																			backgroundColor: selectedTeams.findIndex((o) => o.sky_id === leg.sky_id) !== -1 ? '#1D1A1F' : '#151515',
																		}}
																		onClick={() => {
																			if (selectedTeams.findIndex((o) => o.sky_id === leg.sky_id) === -1) {
																				setselectedTeams([leg])

																				const desiredData2 = {
																					id: trackData.id !== undefined ? trackData.id : seatsets.length,
																					teams: {
																						selectedTeam: [leg],
																					},
																					fromSeat: trackData.fromSeat !== undefined ? trackData.fromSeat : '',
																					gamesID: trackData.gamesID !== undefined ? trackData.gamesID : [],
																					row: trackData.row !== undefined ? trackData.row : '',
																					section: trackData.section !== undefined ? trackData.section : '',
																					toSeat: trackData.toSeat !== undefined ? trackData.toSeat : '',
																					price: trackData.price !== undefined ? trackData.price : '',
																				}

																				const desiredArray = [desiredData2]

																				const registerStepData = {
																					name: credential.name,
																					email: credential.email,
																					seatSets: desiredArray.map((item) => {
																						return {
																							teamId: item.teams.selectedTeam[0].sky_id,
																						}
																					}),
																				}
																				console.log('~teamsmobile.tsx team-selected (non-local teams tab)', registerStepData)

																				registerStepRequest(registerStepData)

																				// alert('e2-2_team_selected..')
																				// this is click handler for tabs other than local tab. ~sahil
																				// ReactGA.event({
																				// 	category: JSON.stringify(desiredData2) || '_EMPTY_DATA',
																				// 	action: 'registration-submit__team-selected',
																				// 	label: 'registration',
																				// })

																				setTrackData(desiredData2)

																				history.push('seats')
																				props.handleNext(3)
																			} else {
																				let arr = selectedTeams.filter((o) => o.sky_id !== leg.sky_id)
																				setselectedTeams(arr)
																			}
																		}}
																	>
																		<div
																			className='card_name'
																			style={{
																				color: '#FAFAFA',
																				fontSize: '22px',
																				fontWeight: 600,
																				fontFamily: 'Atten New !important',
																			}}
																		>
																			{leg.home_team}
																		</div>
																	</Col>
																</Grid>
															)
														})}
													</Row>
													{isTablet ? (
														<Row>
															{' '}
															<Col
																style={{
																	height: '2rem',
																	textAlign: 'right',
																	display: 'flex',
																	justifyContent: 'center',
																}}
															>
																<div>
																	{More ? 'Show Less' : 'Show More'} &nbsp;
																	<img
																		src={Vector}
																		onClick={() => {
																			SetMore(!More)
																		}}
																		style={{cursor: 'pointer'}}
																	/>
																</div>
															</Col>
														</Row>
													) : null}
												</TabPanel>
											)
										}
								  })
								: leaguearray.slice(0).map((val, i) => {
										if (val === 'Local Teams') {
											const arr = DataArray.filter((value) => value.State === region)
											return (
												<TabPanel value={value} index={i} key={Math.random()}>
													<Row style={{marginTop: '1rem'}}>
														{arr1.length > 0
															? arr?.slice(0, arr.length).map((leg: any, index: any) => {
																	return (
																		<Grid container item xs={6} md={2} sm={3} key={index}>
																			<Col
																				className='cardMobile'
																				style={{
																					width: 150,
																					height: 110,
																					userSelect: 'none',
																					marginRight: '5px',
																					marginBottom: '10px',
																					paddingTop: '20%',
																					wordBreak: 'break-word',
																				}}
																				onClick={() => {
																					// alert('xboom4')
																					if (selectedTeams.findIndex((o) => o.sky_id === leg.sky_id) === -1) {
																						setselectedTeams([leg])
																						setTrackData({
																							id: trackData.id !== undefined ? trackData.id : seatsets.length,
																							teams: {
																								selectedTeam: [leg],
																							},
																							fromSeat: trackData.fromSeat !== undefined ? trackData.fromSeat : '',
																							gamesID: trackData.gamesID !== undefined ? trackData.gamesID : [],
																							row: trackData.row !== undefined ? trackData.row : '',
																							section: trackData.section !== undefined ? trackData.section : '',
																							toSeat: trackData.toSeat !== undefined ? trackData.toSeat : '',
																							price: trackData.price !== undefined ? trackData.price : '',
																						})
																						history.push('seats')
																						props.handleNext(3)
																					} else {
																						let arr = selectedTeams.filter((o) => o.sky_id !== leg.sky_id)
																						setselectedTeams(arr)
																					}
																				}}
																			>
																				<div
																					className='card_name'
																					style={{
																						fontFamily: 'Atten New !important',
																						fontSize: '22px',
																					}}
																				>
																					{arr.length === 0 ? <div>No Local Teams</div> : leg.home_team}
																				</div>
																			</Col>
																		</Grid>
																	)
															  })
															: null}
													</Row>
													{isTablet ? (
														<Row>
															{' '}
															<Col
																style={{
																	height: '2rem',
																	textAlign: 'right',
																	display: 'flex',
																	justifyContent: 'center',
																}}
															>
																<div>
																	{More ? 'Show Less' : 'Show More'} &nbsp;
																	<img
																		src={Vector}
																		onClick={() => {
																			SetMore(!More)
																		}}
																		style={{cursor: 'pointer'}}
																	/>
																</div>
															</Col>
														</Row>
													) : null}
												</TabPanel>
											)
										} else {
											const arr = DataArray.filter((value) => value.League === val)
											return (
												<TabPanel value={value} index={i} key={Math.random()}>
													<Row style={{marginTop: '1rem'}}>
														{arr?.slice(0, arr.length).map((leg: any, index: any) => {
															console.log(val)

															return (
																<Grid container item xs={6} md={2} sm={3}>
																	<Col
																		className='cardMobile'
																		key={leg.sky_id}
																		style={{
																			width: 150,
																			height: 110,
																			// height: "100px",
																			userSelect: 'none',
																			marginRight: '5px',

																			marginBottom: '14px',

																			display: 'flex',
																			borderColor: selectedTeams.findIndex((o) => o.sky_id === leg.sky_id) !== -1 ? '#E7E1C5' : '#2D2B2F',
																			backgroundColor: selectedTeams.findIndex((o) => o.sky_id === leg.sky_id) !== -1 ? '#31302A' : '#151515',
																		}}
																		onClick={() => {
																			if (selectedTeams.findIndex((o) => o.sky_id === leg.sky_id) === -1) {
																				setselectedTeams([leg])
																				setTrackData({
																					id: trackData.id !== undefined ? trackData.id : seatsets.length,
																					teams: {
																						selectedTeam: [leg],
																					},
																					fromSeat: trackData.fromSeat !== undefined ? trackData.fromSeat : '',
																					gamesID: trackData.gamesID !== undefined ? trackData.gamesID : [],
																					row: trackData.row !== undefined ? trackData.row : '',
																					section: trackData.section !== undefined ? trackData.section : '',
																					toSeat: trackData.toSeat !== undefined ? trackData.toSeat : '',
																					price: trackData.price !== undefined ? trackData.price : '',
																				})
																				history.push('seats')
																				props.handleNext(3)
																			} else {
																				let arr = selectedTeams.filter((o) => o.sky_id !== leg.sky_id)
																				setselectedTeams(arr)
																			}
																		}}
																	>
																		<div
																			className='card_name'
																			style={{
																				fontFamily: 'Atten New',
																				border: selectedTeams.findIndex((o) => o.sky_id === leg.sky_id) !== -1 ? '#BC936C' : '#FAFAFA',
																				color: selectedTeams.findIndex((o) => o.sky_id === leg.sky_id) !== -1 ? '#E7E1C5' : '#FAFAFA',
																				fontSize: '22px',
																			}}
																		>
																			{leg.home_team}
																		</div>
																	</Col>
																</Grid>
															)
														})}
													</Row>
													{isTablet ? (
														<Row>
															{' '}
															<Col
																style={{
																	color: '#BC936C',
																	height: '2rem',
																	textAlign: 'right',
																	display: 'flex',
																	justifyContent: 'center',
																}}
															>
																<div>
																	{More ? 'Show Less' : 'Show More'} &nbsp;
																	<img
																		src={Vector}
																		onClick={() => {
																			SetMore(!More)
																		}}
																		style={{cursor: 'pointer'}}
																	/>
																</div>
															</Col>
														</Row>
													) : null}
												</TabPanel>
											)
										}
								  })}
						</div>
					)
				})}
			</div>
			{/* {isSE ? (
        <Col
          style={{
            width: "100%",
            marginTop: "10%",
            marginBottom: "5%",
          }}
        >
          <Row style={{ marginRight: "0px", marginLeft: "0px" }}>
            <Button
              variant="contained"
              style={{
                width: "100%",
                height: "50px",
                borderRadius: 50,
                backgroundColor: "#BC936C",
                float: "right",
              }}
              onClick={() => {
                handleSubmit()
              }}
            >
              <div style={{ color: "#FCFCFC" }}>NEXT</div>
            </Button>
          </Row>
          <Row style ={{ marginLeft : "0px" , marginRight : "0px", marginTop : "1rem" }}>
            <Button
              variant="contained"
              style={{
                width: "100%",
                height: "50px",
                borderRadius: 50,
                backgroundColor: "#BC936C",
                float: "left",
              }}
              onClick={(e) => {
                props.handleBack(e);
              }}
            >
              <div style={{ color: "#FCFCFC" }}>BACK</div>
            </Button>
          </Row>
        </Col>
      ) : (
        <Row
          style={{
            width: "100%",
            justifyContent: "space-between",
            marginTop: "10%",
            paddingBottom: "5%",
            marginLeft: isMobileOnly ? "-1px" : "",
          }}
        >
          <Col>
            <Button
              variant="contained"
              style={{
                width: 150,
                height: "50px",
                borderRadius: 50,
                backgroundColor: "#BC936C",

              }}
              onClick={(e) => {
                props.handleBack(e);
              }}
            >
              <div style={{ color: "#FCFCFC" }}>BACK</div>
            </Button>
          </Col>{" "}
          <Col style={{ paddingRight: isTablet ? "75px" : "" }}>
            <Button
              variant="contained"
              style={{
                width: 150,
                height: "50px",
                borderRadius: 50,
                backgroundColor: "#BC936C",
                float: "right",
              }}
              onClick={() => {
                handleSubmit();
              }}
            >
              <div style={{ color: "#FCFCFC" }}>NEXT</div>
            </Button>
          </Col>
        </Row>
      )} */}
		</>
	)
}
