import React, {useState, useEffect, useContext} from 'react'
import TextField from '../../components/TextField/TextField'
import TiqLogo from '../../images/TiqLogo.svg'
import confirmEmailImage from '../../images/confirm-email.svg'
import backIcon from '../../images/back.svg'
import {Redirect, useHistory} from 'react-router'
import './login-styles.css'
import {isMobile} from 'react-device-detect'
import OtpInput from 'react-otp-input'
import thankyou from '../../images/thankyou.svg'
import {sendOtp, checkEmailExist, loginWithOtp} from '../../utils/ApiManager'
import {loginErrorContext} from '../../UserContext'
import CircularProgress from 'material-ui/CircularProgress'
import Loader from '../../components/Loader'
import swal from 'sweetalert'

const emailValidation = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
const LoadingHeader = React.memo(({backTapped, bstrapProgress}) => {
	return (
		<div
			className='LoadingHeader d-flex flex-column justify-content-center align-items-start'
			style={{
				height: isMobile ? '104px' : '101px',
			}}
		>
			<div style={{margin: isMobile ? 'auto 24px' : '15px 80px'}}>
				{isMobile ? <img className='back-icon' src={backIcon} alt='' onClick={backTapped} /> : null}
				<img width='172px' height='36px' src={TiqLogo} alt='' />
			</div>
			<div className='progress-div'>
				<div className={`loader-line w-${bstrapProgress}`} />
				<div className='track-line' />
			</div>
		</div>
	)
})

const EmailForm = React.memo(({onChange, onContinue, loading}) => {
	const history = useHistory()
	return (
		<form className={`email-div ${isMobile ? 'mobile-layout' : null} d-flex flex-column flex-grow-1`} style={{marginTop: isMobile ? '40px' : '80px'}}>
			<div
				className='login-text'
				style={{
					marginBottom: isMobile ? '40px' : '84px',
					fontSize: isMobile ? '24px' : '40px',
				}}
			>
				Login
			</div>
			<TextField type='email' labelText='Email' important onChange={onChange} />
			{loading ? (
				<div
					className='loading-screen-listing-delisting'
					style={{
						// background: 'grey',
						position: 'absolute',
						width: '100vw',
						height: '100vh',
						top: 0,
						left: 0,
						zIndex: 5000,
						background: 'rgba(0, 0, 0, 0.9)',
					}}
				>
					<Loader text='Logging you in ...' />
				</div>
			) : (
				''
			)}
			<div className='flex-grow-1' />
			<button
				className={`default-button ${isMobile ? null : 'desktop'}`}
				onClick={(e) => {
					e.preventDefault()
					onContinue && onContinue()
				}}
			>
				{loading ? <CircularProgress color='#A39C73' /> : <div>Continue</div>}
			</button>
			<div className='sign-up-text'>
				Don’t have an account?{' '}
				<span className='sign-up' onClick={() => history.push('/start')}>
					Sign up
				</span>
			</div>
		</form>
	)
})

const OtpForm = React.memo(({value, onChange, onContinue, otpload, resendTapped}) => {
	console.log(otpload)
	return (
		<form className={`otp-div d-flex flex-column flex-grow-1 justify-content-start mt-3`}>
			<img src={confirmEmailImage} alt='' />
			<div className='otp-dets-div'>
				<div className='enter-code-div'>Enter the code</div>
				<div className='desc-div'>Please enter the code we just sent to your email.</div>
				<OtpInput
					value={value}
					onChange={onChange}
					numInputs={6}
					isInputNum={true}
					inputStyle={{
						color: 'white',
						width: isMobile ? '41px' : '50px',
						height: isMobile ? '41px' : '50px',
						borderColor: '#DFE0E6',
						borderRadius: '8px',
						borderLeftWidth: '1px',
						borderTopWidth: '1px',
						borderLeftColor: '#DFE0E6',
						borderTopColor: '#DFE0E6',
						backgroundColor: '#171718',
						fontSize: '16px',
						borderStyle: 'inherit',
					}}
					separator={<span>&nbsp;&nbsp;</span>}
				/>
				<div className='sign-up-text'>
					Didn’t get the code yet?{' '}
					<span className='sign-up' onClick={resendTapped}>
						Resend
					</span>
				</div>
				{otpload ? (
					<CircularProgress color='#A39C73' />
				) : (
					<button
						className={`default-button ${isMobile ? null : 'desktop'}`}
						onClick={(e) => {
							e.preventDefault()
							onContinue && onContinue()
						}}
					>
						<div> Continue</div>
					</button>
				)}
			</div>
		</form>
	)
})

const ThankYouView = (props) => {
	const history = useHistory()

	useEffect(() => {
		const intervalId = setInterval(() => {
			window.location.href = '/home'
		}, 5000)
		return () => {
			clearTimeout(intervalId)
		}
	}, [props.error])
	return (
		<div
			className='loading-screen-listing-delisting'
			style={{
				// background: 'grey',
				position: 'absolute',
				width: '100vw',
				height: '100vh',
				top: 0,
				left: 0,
				zIndex: 5000,
				background: 'rgba(0, 0, 0, 0.9)',
			}}
		>
			<Loader text='Logging you in ...' />
		</div>
	)
}

function LoginView() {
	const history = useHistory()
	const [error, seterror] = useState()
	const [loading, setloading] = useState()
	const [otpload, setotpload] = useState()
	const {loginerror, setloginerror} = useContext(loginErrorContext)
	const [state, setstate] = useState({
		email: '',
		otp: '',
		showEmail: true, //true,
		showOtp: false, //false,
		progress: '50',
		provisClientId: null,
	})
	const EmailHandleChange = (e) => {
		setstate({...state, email: e.target.value})
		setloginerror('')
	}

	useEffect(() => {
		let urlpath = window.location.pathname
		if (emailValidation.test(state.email) && urlpath === '/login-otp') {
			setstate({...state, showEmail: false, showOtp: true})
		}
		if (state.progress === '100') setstate({...state, showEmail: false, showOtp: false})
	}, [])

	const sendOtpToEmail = () => {
		setotpload(true)
		sendOtp(state.email, (success) => {
			setstate({
				...state,
				showEmail: false,
				showOtp: true,
				progress: '75',
				// provisClientId: success.data.provisClientId,
			})
			setotpload(false)
		})
	}

	return (
		<div className='LoginView d-flex flex-column position-relative'>
			<LoadingHeader bstrapProgress={state.progress} backTapped={() => history.goBack()} />
			{state.showEmail ? (
				<EmailForm
					onChange={(e) => EmailHandleChange(e)}
					onContinue={() => {
						if (!emailValidation.test(state.email)) return
						setloading(true)
						checkEmailExist(state.email, (success) => {
							const resp = success.data.response

							if (resp) {
								setloginerror('')
								sendOtpToEmail()
							} else {
								setloginerror('Email not found, please use signup button below to register')
							}
							setloading(false)
						})
					}}
					loading={loading}
				/>
			) : null}
			{state.showOtp ? (
				<OtpForm
					value={state.otp}
					resendTapped={sendOtpToEmail}
					onChange={(otp) => setstate({...state, otp: otp})}
					otpload={otpload}
					onContinue={() => {
						if (state.otp.length < 6) return
						setotpload(true)
						loginWithOtp(
							{
								email: state.email,
								otp: state.otp,
							},
							(success) => {
								setstate({
									...state,
									showEmail: false,
									showOtp: false,
									progress: '100',
								})
								setotpload(false)
							},
							(error) => {
								swal('Error!', error.message, 'error')
								seterror(error)
								setotpload(false)
							}
						)
					}}
				/>
			) : null}
			{state.progress === '100' ? <ThankYouView error={error} /> : null}
		</div>
	)
}

export default React.memo(LoginView)
