import React, { useState, useEffect, useContext, useRef } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Checkbox from "@material-ui/core/Checkbox";
import { isMobile, isTablet } from "react-device-detect";
import Search from "./search";
import { Row, Col, Container } from "react-bootstrap";
import { Button } from "@material-ui/core";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store/index";
import { addSeatSets, updateSeatSets } from "../store/seatSets/action";
import { Dispatch } from "redux";
import { API_END_POINT, CONFIG_DETAILS } from "../utils/Constant";
import { CircularProgress } from "material-ui";
import moment from "moment";
import swal from "sweetalert";
import {
  DataContext,
  myiconContext,
  toggleContext,
  UpdateForm,
  verifytoggle,
} from "../UserContext";
import VerifyResaleTicket from "./VerifyResaleTicket";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      width: "100%",
    },
    formControl: {
      margin: theme.spacing(3),
    },
    buttonAll: {
      width: "auto",
      height: "50px",
      fontWeight: 400,
      borderRadius: 5,
      backgroundColor: "rgba(0, 133, 255, 0.1)",
      borderStyle: "solid",
      borderWidth: "1px",
      borderColor: "#0085FF",
      fontSize: isMobile ? "3vw" : "16px",
      boxSizing: "border-box",
      fontFamily: "Roboto",
    },
    buttonSelected: {
      width: "auto",
      height: "50px",
      fontWeight: 500,
      borderRadius: 5,
      borderStyle: "solid",
      borderWidth: "1px",
      borderColor: "#DFE0E6",
      fontSize: isMobile ? "3vw" : "16px",
      boxSizing: "border-box",
      fontFamily: "Roboto",
    },
  })
);
interface Data {
  awayTeamId: number;
  datetime: string;
  description: string;
  gameId: string;
  homeTeamId: number;
}
interface props {
  activeStep: number;
  handleBack(value: React.BaseSyntheticEvent): void;
  handleNext(value: any): void;
  backhandler(value: any): void;
  handleReset(): void;
  doubleBack(): void;
}
const WhichGame: React.FC<props> = (props) => {
  const classes = useStyles();
  const { trackData, setTrackData } = useContext(DataContext);
  const [selectedID, setselectedID] = useState<Data[]>(
    trackData.gamesID !== undefined ? trackData.gamesID : []
  );
  const [games, setGames] = useState<any>([]);

  const [teamShow, setteamShow] = useState<any>([]);
  const { verify, setverify } = useContext(verifytoggle);
  const [checkall, setcheckall] = useState<boolean>(false);
  const [toggle, settoggle] = useState<boolean>(true);
  const [CopyData, setCopyData] = useState<any>([]);
  const { updateform, setupdateform } = useContext(UpdateForm);
  // const { Game, setGame } = useContext(GameContext);
  console.log(selectedID);
  const { Icon, setIcon } = useContext(myiconContext);
  const handleChange = (value: Data) => {
    setselectedID((selectedID) => [...selectedID, value]);
  };
  const dispatch: Dispatch<any> = useDispatch();
  let seatsets = useSelector((state: RootState) => {
    return state.seatsets.seatSets;
  });
  const ToggleHandler = (value: any) => {
    settoggle(value);
  };
  const searchedText = useRef("");

  React.useEffect(() => {
    async function FetchData() {
      var bodyData = new FormData();
      let requestHeaders: any = { "Content-Type": "multipart/form-data" };
      bodyData.append("name", "123");
      const id = trackData.teams.selectedTeam[0].sky_id;
      const response = await axios({
        method: "get",
        url: `${process.env.REACT_APP_ROOT_URL}${API_END_POINT.GAME}/${id}`,
        headers: requestHeaders,
        data: bodyData,
      });

      if (response.data.length > 0) {
        setGames(response.data);

        setteamShow(response.data);
        setCopyData(response.data);
      } else {
        swal({
          title: "Sorry!",
          text: "Sorry No Games",
          icon: "warning",
        });
      }
    }
    FetchData();
  }, [CopyData.length === 0]);

  const handleRemove = (value: Data) => {
    let removedArry = selectedID.filter((val) => val.gameId !== value.gameId);
    setselectedID((selectedID) => removedArry);
  };
  useEffect(() => {
    selectedID.length == teamShow.length && teamShow.length != 0
      ? setcheckall(true)
      : setcheckall(false);
  }, [selectedID]);

  const selectAll = () => {
    var arr = teamShow.map((val: any) => {
      return val;
    });
    setcheckall(true);
    setselectedID((selectedID) => arr);
  };

  const removeAll = () => {
    setcheckall(false);
    setselectedID((selectedID) => []);
  };

  const handlesubmit = () => {
    setTrackData({
      teams: {
        selectedTeam: trackData.teams.selectedTeam,
      },
      gamesID: selectedID,
    });
    setTrackData({
      id: trackData.id,
      teams: {
        selectedTeam: trackData.teams.selectedTeam,
      },
      fromSeat: trackData.fromSeat !== undefined ? trackData.fromSeat : "",
      gamesID: selectedID,
      row: trackData.row !== undefined ? trackData.row : "",
      section: trackData.section !== undefined ? trackData.section : "",
      toSeat: trackData.toSeat !== undefined ? trackData.toSeat : "",
      price: trackData.price !== undefined ? trackData.price : "",
    });

    if (updateform) {
      console.log("sss ", {
        id: trackData.id,
        teams: {
          selectedTeam: trackData.teams.selectedTeam,
        },
        gamesID: selectedID,
        section: trackData.section,
        row: trackData.row,
        toSeat: trackData.toSeat,
        fromSeat: trackData.fromSeat,
        price: trackData.price,
      });
      dispatch(
        updateSeatSets(
          {
            id: trackData.id,
            teams: {
              selectedTeam: trackData.teams.selectedTeam,
            },
            gamesID: selectedID,
            section: trackData.section,
            row: trackData.row,
            toSeat: trackData.toSeat,
            fromSeat: trackData.fromSeat,
            price: trackData.price,
          },
          trackData.id
        )
      );
      setupdateform(false);
      // setheading(true)
    } else {
      dispatch(
        addSeatSets({
          id: trackData.id,
          teams: {
            selectedTeam: trackData.teams.selectedTeam,
          },
          gamesID: selectedID,
          section: trackData.section,
          row: trackData.row,
          toSeat: trackData.toSeat,
          fromSeat: trackData.fromSeat,
          price: trackData.price,
        })
      );
    }

    setverify(false);
    setIcon([
      {
        active: false,
        completed: true,
        error: false,
        icon: 1,
      },
      {
        active: false,
        completed: true,
        error: false,
        icon: 2,
      },
      {
        active: false,
        completed: true,
        error: false,
        icon: 3,
      },
      {
        active: false,
        completed: true,
        error: false,
        icon: 4,
      },
    ]);
    // setheading(true)
  };

  console.log(trackData);

  const onSearch = async (search: string) => {
    // if (search.length === 0) {
    //   setteamShow(games);
    // } else {
    //   let arr = [...games];
    //   setteamShow(
    //     arr.filter((val: any) => {
    //       var ser = val.description.toLocaleLowerCase();

    //       return ser.includes(search.toLocaleLowerCase());
    //     })
    //   );
    // }

    let condition = new RegExp(search.toLocaleLowerCase());
    var result = await Promise.all(
      teamShow.filter(function (el: any) {
        return condition.test(el.description.toLocaleLowerCase());
      })
    );
    searchedText.current = search;
    setteamShow(result);
    if (search.length === 0) {
      setteamShow(CopyData);
    }
  };
  return (
    <div>
      {verify === true ? (
        <>
          <div
            className="label"
            style={{
              fontSize: "40px",
              color: "#BC936C",
              fontWeight: 700,
              lineHeight: "30px",
              fontFamily: "Atten New",
              paddingTop: "15px",
            }}
          >
            {/* Which  {trackData.teams.selectedTeam[0].home_team}  Games do you own ? */}
            {/* {seatsets.length > 1 ? (
              <div
                className="label"
                style={{
                  fontSize: "40px",
                  color: "#BC936C",
                  fontWeight: 700,
                  lineHeight: "30px",
                  fontFamily: "Atten New",
                  paddingTop: "3rem",
                }}
              >
                Which {seatsets[0].teams.selectedTeam[0].home_team} games do you
                own ? <br/><br/>
                <p style ={{ fontSize : "25px" , fontWeight : 400 ,}}> [ for Section - {seatsets[0].section} , Row - {seatsets[0].row}{" "} ,
                Seats - {seatsets[0].fromSeat} to {seatsets[0].toSeat} ]
                </p>
              </div>
            ) : ( */}
            <div
              className="label"
              style={{
                fontSize: "40px",
                color: "#BC936C",
                fontWeight: 700,
                lineHeight: "30px",
                fontFamily: "Atten New",
                paddingTop: "3rem",
              }}
            >
              {" "}
              Which {trackData.teams.selectedTeam[0].home_team} games do you own
              ? <br /> <br />
              <p style={{ fontSize: "25px", fontWeight: 400 }}>
                {" "}
                [ for Section {trackData.section} , Row {trackData.row} , Seats{" "}
                {trackData.fromSeat} to {trackData.toSeat} ]
              </p>
            </div>
            {/* )} */}
          </div>
          <Search onSearch={onSearch} />
          <div
            className="container"
            style={{ marginTop: "5%", paddingLeft: !checkall ? "0px" : "-5%" }}
          >
            {/* { teamShow.length > 0 ? (
          <CircularProgress />
        ) : ( */}
            <Container>
              <Row>
                <Col style={{}}>
                  <div className="form-check">
                    <Row>
                      <Checkbox
                        checked={checkall}
                        onChange={checkall ? removeAll : selectAll}
                        style={{
                          color: "#BC936C",
                          width: "24px",
                          height: "24px",
                        }}
                      />

                      <div
                        className="col-sm-4"
                        style={{
                          color: " white",
                          alignSelf: "center",
                          fontFamily: "Atten New",
                          fontSize: isMobile ? "3vw" : "16px",
                          textAlign: "left",
                          paddingLeft: "3%",
                        }}
                      >
                        Select all
                      </div>
                    </Row>
                  </div>
                </Col>
              </Row>
              {checkall
                ? selectedID?.map((value) => {
                    const selectedIds = selectedID.map(
                      (employee) => employee.gameId
                    );
                    console.log("sdsad", selectedIds);

                    return (
                      <Row
                        style={{
                          borderBottomStyle: "solid",
                          paddingTop: "30px",
                          paddingBottom: "30px",
                          borderBottomColor: "#2C2A2E",
                        }}
                      >
                        <Col style={{}}>
                          <div className="form-check">
                            <Row style={{}}>
                              <Checkbox
                                // onMouseOver={}
                                checked={
                                  selectedIds.includes(value.gameId)
                                    ? true
                                    : false
                                }
                                onChange={
                                  selectedIds.includes(value.gameId)
                                    ? () => handleRemove(value)
                                    : () => handleChange(value)
                                }
                                name={value.description}
                                style={{
                                  color: "#BC936C",
                                  width: "24px",
                                  height: "24px",
                                }}
                              />
                              <div
                                className="col-9"
                                style={{ paddingLeft: "3%" }}
                              >
                                <div
                                  style={{
                                    color: "#FAFAFA",
                                    textAlign: "left",
                                    alignSelf: "center",
                                    fontSize: isMobile ? "3vw" : "18px",
                                    fontWeight: 500,
                                    fontFamily: "Atten New",
                                  }}
                                >
                                  {value.description}
                                </div>
                                <div
                                  style={{
                                    opacity: "60%",
                                    textAlign: "initial",
                                    fontSize: isMobile ? "3vw" : "16px",
                                    fontFamily: "Atten New",
                                    color: "#FAFAFA",
                                  }}
                                >
                                  {moment(value.datetime).format("llll")}
                                </div>
                              </div>
                            </Row>
                          </div>
                        </Col>
                      </Row>
                    );
                  })
                : teamShow.length > 0 &&
                  teamShow?.map((value: any, i: any) => {
                    const selectedIds = selectedID.map(
                      (employee) => employee.gameId
                    );

                    return (
                      <Row
                        style={{
                          borderBottomStyle: "solid",
                          paddingTop: "30px",
                          paddingBottom: "30px",
                          borderBottomColor: "#2C2A2E",
                        }}
                      >
                        <Col style={{}}>
                          <div className="form-check">
                            <Row style={{}}>
                              <Checkbox
                                checked={
                                  selectedIds.includes(value.gameId)
                                    ? true
                                    : false
                                }
                                onChange={
                                  selectedIds.includes(value.gameId)
                                    ? () => handleRemove(value)
                                    : () => handleChange(value)
                                }
                                name={value.title}
                                style={{
                                  color: "#BC936C",
                                  width: "24px",
                                  height: "24px",
                                }}
                              />
                              <div
                                className="col-9"
                                style={{ paddingLeft: "3%" }}
                              >
                                <div
                                  style={{
                                    color: "white",
                                    textAlign: "left",
                                    alignSelf: "center",
                                    fontSize: isMobile ? "3vw" : "18px",
                                    fontWeight: 500,
                                    fontFamily: "Atten New",
                                  }}
                                >
                                  {value.description}
                                </div>
                                <div
                                  style={{
                                    opacity: "60%",
                                    textAlign: "initial",
                                    fontSize: isMobile ? "3vw" : "16px",
                                    fontFamily: "Atten New",
                                    color: "white",
                                  }}
                                >
                                  {moment(value.datetime).format("llll")}
                                </div>
                              </div>
                            </Row>
                          </div>
                        </Col>
                      </Row>
                    );
                  })}
            </Container>
            )
          </div>

          <div
            className={props.activeStep === 0 ? "" : "row"}
            style={{
              width: isMobile && isTablet ? "" : "92%",
              marginLeft: isMobile && isTablet ? "" : "40px",
              justifyContent: "space-between",
              marginTop: "65px",
              marginBottom: "65px",
              paddingLeft: "6.6rem",
              paddingRight: "5.6rem",
            }}
          >
            <div>
              {props.activeStep !== 0 && (
                <Button
                  variant="contained"
                  style={{
                    width: 150,
                    height: 50,
                    boxShadow:
                      "0px 0px 0px -0px rgba(0,0,0,0), 0px 0px 0px 0px rgba(0,0,0,0), 0px 0px 0px 0px rgba(0,0,0,0)",
                    borderRadius: 50,
                    backgroundColor: "#5B5B5B",
                  }}
                  disabled={props.activeStep === 0}
                  onClick={(e) => {
                    props.handleBack(e);
                  }}
                >
                  <div style={{ color: "white" }}>BACK</div>
                </Button>
              )}
            </div>
            <div>
              <Button
                variant="contained"
                disabled={selectedID.length === 0 ? true : false}
                style={{
                  width: 150,
                  height: 50,
                  borderRadius: 50,
                  boxShadow:
                    "0px 0px 0px -0px rgba(0,0,0,0), 0px 0px 0px 0px rgba(0,0,0,0), 0px 0px 0px 0px rgba(0,0,0,0)",
                  backgroundColor:
                    selectedID.length === 0 ? "#5B5B5B" : "#C57A1E",
                }}
                onClick={() => handlesubmit()}
              >
                <div style={{ color: "#FCFCFC" }}>
                  {props.activeStep === 0 ? "CONTINUE" : "NEXT"}
                </div>
              </Button>
            </div>
          </div>
        </>
      ) : (
        <VerifyResaleTicket
          activeStep={props.activeStep}
          handleReset={props.handleReset}
          handleNext={props.handleNext}
          handleBack={props.handleBack}
          doubleBack={props.doubleBack}
          handletoggle={ToggleHandler}
          backhandler={props.backhandler}
        />
      )}
    </div>
  );
};
export default WhichGame;
