import { CredentialActionTypes, credentialState, REMOVE_CREDENTIAL, STORE_CREDENTIAL } from './types'

export const initialState: credentialState = {
    name: '' ,
    email: ''
}

export function credentialReducer(
    state = initialState,
    action: CredentialActionTypes
  ): credentialState {
    switch (action.type) {
      case STORE_CREDENTIAL: {
        
        return {
          ...state,
          ...action.payload
        }
      }
     
      default:
        return state
    }
  }