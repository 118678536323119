import React from "react";
import "./TextField.css";
import { isMobile } from "react-device-detect";
import { useContext } from "react";
import { loginErrorContext } from "../../UserContext";

function TextField({
  important = false,
  labelText = "Label",
  placeholder = "Enter your email address",
  type = "test",
  onChange,
}) {
  const {loginerror , setloginerror} = useContext(loginErrorContext)
  console.log(loginerror)
  return (
    <div
      className="TextField position-relative d-flex justify-content-center align-items-start flex-column"
      style={{ minWidth: "100%", minHeight: "83px" }}
    >
      <div
        className="text-label"
        style={{
          marginBottom: isMobile ? "14px" : "10px",
        }}
      >
        {labelText}
        {important ? <span className="important">*</span> : null}
      </div>
      <input
        className={`default-txt-field ${isMobile ? null : "desktop"}`}
        placeholder={placeholder}
        type={type}
        onChange={onChange}
      />
     <div style={{
       color:"red"
     }}>{loginerror}</div> 
    </div>
  );
}

export default React.memo(TextField);
