import jwt from 'jsonwebtoken'
import {useHistory} from 'react-router-dom'
import React from 'react'
import {getRefreshToken} from '../utils/ApiManager'

let l = console.log

export const useVanillaAuth = () => {
	let AuthData = JSON.parse(localStorage.getItem('Auth0Data') || '{}') // DO NOT USE EMPTY STRING IN JSON PARSE!!
	const [loading, setloading] = React.useState(false)
	let user: any = jwt.decode(AuthData.id_token) || {} //🧿︎
	const History = useHistory()
	console.log('USER DATA', user)
	// console.log('AUTHDATA', AuthData)

	const getIdTokenClaims = async () => {
		const currentTime = () => Math.floor(new Date().getTime() / 1000)

		l('~sahil exp typeof ', user.exp)
		// ? CONVERTING IAT TO JS DATE // new Date(Math.floor(iatOrExp * 1000))

		const isExpired = currentTime() > user.exp // We checking th expirty time of 🧿︎ `id_token`.
		// const isExpired = true //! for debug only.

		if (isExpired) {
			// make async request to fetch new ac and rt using `AuthData.id_token`.
			let newUser = await getRefreshToken(AuthData.refresh_token)
			// debugger

			l('~sahil user newly generated via refresh token', newUser)

			if (newUser) {
				// todo:
				AuthData.id_token = newUser.id_token // reassigning the value to ``AuthData`` after we do refreshing.
				AuthData.access_token = newUser.access_token // reassigning the value to ``AuthData`` after we do refreshing.

				localStorage.setItem('Auth0Data', JSON.stringify(AuthData))
			}
		}

		return {__raw: AuthData.id_token}
	}

	const logout = () => {
		localStorage.removeItem('Auth0Data')
		localStorage.clear() // Clear everything in localStorage.
		History.push('/')
		window.location.reload()
	}

	// this is new ..
	const userId = user['https://tiqassist.com/user_id']

	return {
		isAuthenticated: Boolean(AuthData.access_token),
		user,
		getIdTokenClaims,
		logout,
		loading,
		userId,
	}
}
