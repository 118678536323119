import AppBar from '@material-ui/core/AppBar'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import Typography from '@material-ui/core/Typography'
import {useHistory} from 'react-router-dom'
import LOGO from '../images/logo.png'
import {useState} from 'react'
import {useContext} from 'react'
import {forceReRenderContext} from '../UserContext'

export const HeaderMinimal = ({isMobile, form, setform}: {isMobile: boolean; form?: boolean; setform?: any}) => {
	let history = useHistory()
	const {forceReRender} = useContext(forceReRenderContext)

	return isMobile ? (
		<AppBar
			position='static'
			style={{
				// width: isIphone12 ? '100%' : '', //~sahil,, it seems unnecessary IMO.

				boxShadow: ' 0px 4px 4px rgba(0, 0, 0, 0.25)',
				height: '84px',
			}}
		>
			{/* <Toolbar> */
			/* ^^ Purging material ui..*/}
			<div
				className='d-flex justify-content-center align-items-center'
				style={{
					background: 'rgb(1, 1, 1)',
					// border: '2px solid blue',
					padding: '26px 14px',
				}}
			>
				{/* {
                  element.style {
                  border: 2px solid blue;
                  padding: 12px 14px;
                    } */}
				<span
					style={{
						height: '24px',
						width: '24px',
						borderRadius: '50%',
						display: 'inline-block',
						backgroundColor: '#1A1A1A',
						// marginTop: 20,
					}}
				>
					<ArrowBackIcon
						style={{
							marginTop: '-4px' /*This is to fix position white arrow in light grey circle.*/,
							width: '18px',
						}}
						onClick={(e) => {
							if (setform) {
								if (form === true) {
									setform(false)
								} else {
									history.goBack()
								}
							} else {
								// 🔽︎ ~sahil, This logic is for pages other than TeamCredentials.
								history.goBack()
							}

							setTimeout(() => {
								forceReRender({}) //~sahil, just so that tab indicator on header pages get back coz history.push doesn't cause components to re-render.
							})

							//~sahil, Checking if current page is TeamCredential page.
						}}
					/>
				</span>
				{/* <Typography className={classes.title}> */}
				<Typography
					style={{
						marginLeft: isMobile ? '3%' : '50px',
						textAlign: 'left',
					}}
				>
					<img src={LOGO} style={{width: '66%'}} />
				</Typography>
				<div className='flex-grow-1' />
			</div>
			{/* </Toolbar> */}
		</AppBar>
	) : (
		<div>
			<Typography
				style={{
					width: '40px',
					height: '40px',
					marginTop: 48,
					marginLeft: isMobile ? '3%' : '50px',
					textAlign: 'left',
				}}
			>
				<img src={LOGO} />
			</Typography>
		</div>
	)
}
