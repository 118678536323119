import React, { memo, useState } from "react";
import { isMobileOnly } from "react-device-detect";
import searchIcon from "../images/search-icon.svg";
import "./search.css";
interface Props {
  onSearch(value: string): void;
  onFocus?(e: React.FocusEvent<HTMLInputElement>): void;
  onBlur?(e: React.FocusEvent<HTMLInputElement>): void;
}
const Search: React.FC<Props> = (props) => {
  const SearchHandler = (event: React.BaseSyntheticEvent) => {
    props.onSearch(event.currentTarget.value.toLowerCase());
  };

  const [IsTeam, SetIsTeam] = useState<boolean>(
    window.location.pathname === "/teams" ||
      window.location.pathname === "/events"
  );

  return (
    <div
      className="Search flex-center justify-content-start"
      style={{
        borderRadius: IsTeam ? "10px" : "",
        marginBottom: IsTeam ? "10%" : "",
        marginLeft: IsTeam ? (isMobileOnly ? "" : "13.5%") : "",
        width: IsTeam ? "74.5%" : "",
      }}
    >
      <img src={searchIcon} />
      <input
        className="h-100"
        type="text"
        onChange={SearchHandler}
        placeholder={IsTeam ? "Search team here..." : "Search"}
        onFocus={props.onFocus}
        onBlur={props.onBlur}
      />
    </div>
  );
};
export default memo(Search);
