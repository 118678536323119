import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import 'bootstrap/dist/css/bootstrap.min.css'
import {createStore, applyMiddleware, Store, compose} from 'redux'
import {Provider} from 'react-redux'
import {rootReducer} from './store/index'
import thunk from 'redux-thunk'
import {composeWithDevTools} from 'redux-devtools-extension'
import {Auth0Provider} from '@auth0/auth0-react'
import {clientId, redirectUri, domain, audience} from './utils/environmentConfig'

const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
const store2 = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)))

console.log('~environment values(for debugging):', process.env)

ReactDOM.render(
	<React.StrictMode>
		{/* <Auth0Provider domain={domain} clientId={clientId} redirectUri={redirectUri} useRefreshTokens={true} cacheLocation='localstorage' audience={audience}> */}
		<Provider store={store2}>
			<App />
		</Provider>
		{/* </Auth0Provider> */}
	</React.StrictMode>,
	document.getElementById('root')
)
