import {createContext} from 'react'

export const UserContext = createContext<any>(false)
export const loginErrorContext = createContext<any>("")
export const DataContext = createContext<any>({})
export const UpdateForm = createContext<any | undefined>(false)
export const hideData = createContext<any>(false)
export const toggleContext = createContext<any>(false)
export const lengthContext = createContext<any>(0)
export const focusContext = createContext<any>(false)
export const AuthContext = createContext<any>({})
export const loginContext = createContext<any>({})
export const IdContext = createContext<any>({})
export const SignupContext = createContext<any>({})
export const ReloadContext = createContext<any>({})
export const ContextSpinner = createContext<any>(false)
export const verifytoggle = createContext<any>(true)
export const myiconContext = createContext<any>([
  {
    active: true,
    completed: false,
    error: false,
    icon: 1,
  },
  {
    active: false,
    completed: false,
    error: false,
    icon: 2,
  },
  {
    active: false,
    completed: false,
    error: false,
    icon: 3,
  },
  {
    active: false,
    completed: false,
    error: false,
    icon: 4,
  },
])

export const InvContext = createContext<any>([])
export const PaymentScreenContext = createContext<any>([])
export const HideHeaderContext = createContext<any>(false)
export const teamCredContext = createContext<any>([])
export const teamShowContext = createContext<any>([])
export const TotalContext = createContext<any>([])
export const DashboardContext = createContext<any>([])
export const activeTabContext = createContext<any>({}) // ~sahil, added on 27th may.
export const updatedTicketsRefContext = createContext<any>([]) // ~sahil, added on 27th may.
export const forceReRenderContext = createContext<any>({}) // ~sahil, added on 27th may.
