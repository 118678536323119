import React, {useState, useEffect, useContext, useCallback} from 'react'
import {createStyles, makeStyles, Theme, withStyles} from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'

import {Slide, Container, Grid, DialogTitle, DialogContent, DialogContentText} from '@material-ui/core'
import {TransitionProps} from '@material-ui/core/transitions'
import {isAndroid, isChrome, isMobile, isMobileOnly, isTablet} from 'react-device-detect'
import clsx from 'clsx'
import {Alert, Card, Col, Form, Row} from 'react-bootstrap'
import Moment from 'react-moment'
import Divider from '@material-ui/core/Divider'
import Seat from '../images/seats.png'
import '../components/DashboardPopUpScreen.css'
import NBA from '../images/nbas.png'
import MLB from '../images/mlbs.png'
import NFL from '../images/nfls.png'
import NHL from '../images/nhls.png'
import MLS from '../images/mlss.png'
import Seats from '../images/Seat.svg'
import NBABIG from '../images/nbabignew.png'
import MLBBIG from '../images/mlbbignew.png'
import MLSBIG from '../images/mlsbignew.png'
import NFLBIG from '../images/nflbignew.png'
import NHLBIG from '../images/nhlbignew.png'
import Rolling from '../images/rolling.svg'
import {url} from 'node:inspector'
import {AuthContext, ContextSpinner, ReloadContext} from '../UserContext'
// import {useAuth0} from '@auth0/auth0-react'
import axios from 'axios'
import {CircularProgress} from 'material-ui'
import {ENV} from '../utils/environmentConfig'
import {API_END_POINT} from '../utils/Constant'
import {useVanillaAuth} from '../hooks/useVanillaAuth'

const GlobalCss = withStyles({
	'@global': {
		'.MuiDialog-paper': {
			position: 'static',
			width: 'auto',
			margin: '0 auto',
			backgroundColor: 'black',
			// ~sahil ^^^, 10 Jun, 21,, adding this to fix the background color of fullscreen popup window, else its showing white color.
		},
		'.MuiDialog-container': {
			// height: '70vh', // ~sahil 🛑︎🛑︎🛑︎ debug, 🛑︎🛑︎🛑︎
			// height: '100vhvh', // ~sahil 🛑︎🛑︎🛑︎ debug, 🛑︎🛑︎🛑︎
			// Below ``.MuiDialog-paperFullScreen{height: 100%}`` is the new solution now. ~sahil, 3 Jun, 2021.
			// height: '100vh', // ~sahil 🛑︎🛑︎🛑︎ debug, 🛑︎🛑︎🛑︎
			// border: '2px solid green',
		},
		'.MuiDialog-paperFullScreen': {
			// ~sahil 🛑︎🛑︎🛑︎ debug, 🛑︎🛑︎🛑︎
			height: '100%', //lastest push.
			// height: 'auto',
			// border: '2px solid yellow',
		},
	},
})(() => null)

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		appBar: {
			//   position: "relative",
			height: '801px',
		},
		title: {
			flex: 1,
			justifyContent: 'center',
		},
		margin: {
			margin: theme.spacing(1),
			background: ' rgba(255, 255, 255, 0.08)',
			borderRadius: '6px',
		},
		newYork: {
			// height: '44px',
			fontSize: '36.8564px',
			lineHeight: '58px',
			color: ' #E7E1C5',
			fontWeight: 'bold',
			fontFamily: 'Atten New ',
			fontStyle: 'normal',

			// [theme.breakpoints.down('sm')]: {
			//   fontSize: '18px',
			//   marginTop: '-150px',
			//   marginLeft: '0px',
			// },
		},
		knicks: {
			// height: '71px',
			fontSize: '46.0706px',
			lineHeight: '102px',
			color: '#BC936B',
			fontFamily: 'Atten New',
			fontStyle: 'normal',
			fontWeight: 700,
			marginTop: '-20px',

			[theme.breakpoints.down('sm')]: {
				fontSize: '24.46px',
				marginTop: '-33px',
			},
		},
		vs: {
			width: '26px',
			height: '23px',
			fontStyle: 'italic',
			fontWeight: 'bold',
			fontSize: '35px',
			lineHeight: '42px',
			color: '#FFFFFF',
			fontFamily: 'Atten New ',
			[theme.breakpoints.down('sm')]: {
				fontSize: '16px',
				marginTop: '-56px',
				marginLeft: '170px',
			},
		},
		houston: {
			// height: '44px',
			fontSize: '36.8564px',
			lineHeight: '58px',
			color: ' #E7E1C5',
			fontWeight: 'bold',
			fontFamily: 'Atten New ',
			fontStyle: 'normal',

			[theme.breakpoints.down('sm')]: {
				fontSize: '18px',
				// marginLeft: '74px',
				// marginTop: '-49px',
				// marginRight: '-274px',
			},
		},
		rockets: {
			// height: '71px',
			fontSize: '46.0706px',
			// lineHeight: '102px',
			color: '#BC936B',
			fontFamily: 'Atten New',
			fontStyle: 'normal',
			textAlign: 'center',
			fontWeight: 700,

			[theme.breakpoints.down('sm')]: {
				fontSize: '24.46px',
				// lineHeight: '21px',
				// marginLeft: '338px',
				// paddingTop: '27px',
			},
		},
		// paper: {overflow: isMobileOnly ? 'auto' : 'hidden'},//~sahil, trying to fix.
		paper: {overflow: isMobileOnly ? 'auto' : ''},
		date_left: {
			// height: '21px',
			// fontSize: '18px',
			// lineHeight: '21px',
			// color: '#FFFFFF',
		},
		date_right: {
			height: '21px',
			fontSize: '18px',
			lineHeight: '21px',
			color: '#FFFFFF',
			marginLeft: '40px',
			marginTop: '15px',
			marginBottom: '5px',

			[theme.breakpoints.down('sm')]: {
				fontSize: '14px',
				float: 'right',
				marginTop: '5px',

				marginRight: '10px',
			},
		},
		MuiInputBaseInputMuiOutlinedInputInput: {
			alignItems: 'center !important',
			fontSize: '16px',
			lineHeight: '48px',
			color: 'rgba(231, 225, 197, 0.5)',
		},
		clip: {
			width: isMobile ? '100px' : '170px',
			height: isMobile ? '30px' : '42px',
			background: '#C57A1E',
			color: '#FFFFFF',
			// marginTop: "-68px",
			fontSize: '14px',
			lineHeight: '20px',
			border: '2px solid #C57A1E',
			boxSizing: 'border-box',
			borderRadius: '50px',
			marginTop: '15px',
			'&:hover': {
				boxShadow: '0px 4px 22px rgba(197, 122, 30, 0.4)',
			},

			[theme.breakpoints.down('sm')]: {
				fontSize: '14px',
				marginLeft: '-4px',
				marginTop: '10px',
			},
		},
		clipSold: {
			width: 334,
			height: 191,
			color: '#FFFFFF',
			background: 'rgba(0, 0, 0, 0.72)',

			fontSize: '40px',
			marginTop: -165,
			marginLeft: -22,
			[theme.breakpoints.down('sm')]: {
				width: 334,
				height: 200,
				color: '#FFFFFF',
				background: 'rgba(0, 0, 0, 0.72)',
				fontSize: '40px',
				marginTop: -233,
				marginLeft: 0,
			},
		},
		declip: {
			width: isMobile ? '100px' : '170px',

			height: isMobile ? '30px' : '42px',
			background: '#010101',
			color: '#C57A1E',
			// marginTop: "-68px",
			fontSize: '14px',
			lineHeight: '20px',
			border: '2px solid #C57A1E',
			boxSizing: 'border-box',
			borderRadius: '50px',
			marginTop: '15px',
			'&:hover': {
				boxShadow: '0px 4px 22px rgba(197, 122, 30, 0.4)',
			},
			//

			[theme.breakpoints.down('sm')]: {
				fontSize: '14px',
				marginLeft: '-4px',
				marginTop: '10px',
			},
		},
		MuiButtonlabel: {
			marginBottom: isMobile ? '10px' : '',
		},
	})
)

const Transition = React.forwardRef(function Transition(props: TransitionProps, ref: React.Ref<unknown>) {
	// ^^ This doesn't throw error now.~sahil.
	// const Transition = React.forwardRef(function Transition(props: TransitionProps & {children?: React.ReactElement<any, any>}, ref: React.Ref<unknown>) {
	// return <Slide direction='up' ref={ref} {...props} />
	return <Slide direction='up' ref={ref} {...props} timeout={{appear: 2000, enter: 1000, exit: 1000}} />
})

interface Data {
	className: string
	label: string
	details: any
	disabled: any
	onModalClose(str: String): any
	handleListButton(str: String): void
	updatebutton(str: String): void
	setsData: any
	RefreshData(): void
	// changeStatus():void;
}

export default function FullScreenDialog(props: Data) {
	const classes = useStyles()
	const [open, setOpen] = React.useState(false)
	const [isList, setIsList] = React.useState(false)
	const [SeatStatus, setSeatStatus] = React.useState(props.details.status)

	const [Label, setLabel] = React.useState<string>('')
	// const [ClassName, setClassName] = React.useState<string>(props.className);
	const [state, setState] = React.useState({
		open: false,
		isList: false,
	})
	const [buttonText, setbuttonText] = React.useState<string>(props.label)
	const [revert, setrevert] = useState(false)
	const {Reload, setReload} = useContext(ReloadContext)
	const {Auth, setAuth} = useContext(AuthContext)
	const [isSubmitted, setIsSubmitted] = useState(false)
	// const {spinner, setspinner} = useContext(ContextSpinner)
	const [spinner, setspinner] = useState(false)
	// when request completed

	const updatebutton = (Data: any) => {
		return props.details.status === 'NOT_LISTED' ? 'DE-LIST' : 'LIST'
	}
	const {user, getIdTokenClaims} = useVanillaAuth()
	const handleConfirm = useCallback(async (props: Data) => {
		;(async () => {
			let id = user['https://tiqassist.com/user_id']
			try {
				setIsSubmitted(true) //~new-test
				let url1Prod = `${process.env.REACT_APP_ROOT_URL}${API_END_POINT.LIST}/${id}/${props.details.inv_id}`
				let url2Prod = `${process.env.REACT_APP_ROOT_URL}${API_END_POINT.DELIST}/${id}/${props.details.inv_id}`
				// setState({ ...state, open: true });
				setspinner(true)
				let url1DevAndLocal = `https://6dvzj3ijy6.execute-api.us-east-1.amazonaws.com/dev/list/${id}/${props.details.inv_id}`
				let url2DevAndLocal = `https://6dvzj3ijy6.execute-api.us-east-1.amazonaws.com/dev/delist/${id}/${props.details.inv_id}`

				let url1 = ENV === 'prod' ? url1Prod : url1DevAndLocal
				let url2 = ENV === 'prod' ? url2Prod : url2DevAndLocal

				const url = props.details.status === 'NOT_LISTED' ? url1 : props.details.status === 'LISTED' ? url2 : ''

				console.log(props.details)
				var Body = {
					available_seats: props.details.seats,
					selected_seats: props.details.seats,
				}

				const token = await getIdTokenClaims()
				const response = await axios.post(`${url}`, Body, {
					headers: {
						Authorization: `Bearer ${token.__raw}`,
						'Content-Type': 'application/json',
						'Access-Control-Allow-Origin': '*',
						'Access-Control-Allow-Methods': 'POST,OPTIONS',
					},
				})
				setIsList(response.data)
				setbuttonText(props.details.status === 'NOT_LISTED' ? 'DE-LIST' : 'LIST')
				props.updatebutton('list')
				setOpen(false)
				setrevert(!revert)
				props.onModalClose(props.details && props.details.status === 'NOT_LISTED' ? 'De-Listed' : 'Listed')
				setReload(true)
				setIsSubmitted(false) //~new-test
				setspinner(false)
				// console.log("props.details.inv_id", props.details.inv_id);
			} catch (e) {
				console.error(e)
			}
		})()
	}, [])

	const backgroundImage =
		props.details.league && props.details.league.includes('MLB') ? (
			<img className='background-img-sports ' alt='' src={MLB} />
		) : props.details.league && props.details.league.includes('NBA') ? (
			<img className='background-img-sports ' alt='' src={NBA} />
		) : props.details.league && props.details.league.includes('NFL') ? (
			<img className='background-img-sports ' alt='shut eslint2' src={NFL} />
		) : props.details.league && props.details.league.includes('NHL') ? (
			<img className='background-img-sports ' alt='shut eslint2' src={NHL} />
		) : props.details.league && props.details.league.includes('MLS') ? (
			<img className='background-img-sports ' alt='shut eslint2' src={MLS} />
		) : (
			// here goes the null case for the MLS.
			<img className='background-img-sports ' alt='shut eslint2' src={MLS} />
		)

	const handleClickOpen = () => {
		setOpen(true)

		// scrolling to top,

		// VERIFIED>!
		// setTimeout(() => {
		// debugger //~abhinav~test~sahil
		// let elm: any = document.querySelector('.MuiDialog-root')
		// elm.scrollTop = 0 //~sahil-ankur-fixation-sam-bug-4-jun-2021
		// }, 0)
	}

	const handleClose = () => {
		setOpen(false)
	}
	const handleListDelist = () => {
		alert('changed')
	}
	let title_One = props.details.home_team.split(' ')
	let title_Two = title_One.pop(title_One.length - 1)
	//parseInt(props.details.seats[0]) + parseInt(props.details.quantity)

	let maxSeat = props.details.seats[props.details.seats.length - 1]
	let title_three = props.details.away_team.split(' ')
	console.log('~dashboardpopup.tsx title_three', title_three)

	let title_four = title_three.pop(title_three.length - 1)

	function AlertDialog(props: Data) {
		// THIS IS NOT(that is actually in the Home.tsx file) THE FULL SCREEN NOTIFICATION YOU GET WHEN THE LISTED/DE-LISTED is shown in the middle of the screen.
		console.log('inside Alert dialog')
		const [adOpen, setAdOpen] = React.useState(false)

		const handleClose = () => {
			setAdOpen(false)
		}

		return (
			<div style={{overflow: 'auto'}}>
				<Dialog open={adOpen} onClose={handleClose} style={{justifyContent: 'center'}}>
					{props.details.status === 'LISTED' ? 'DeListed' : 'Listed'}
				</Dialog>
			</div>
		)
	}

	const [isSE, setSE] = useState(window.innerWidth < 321)
	const [is8Plus, set8Plus] = useState(window.innerWidth < 415 && window.innerWidth > 410)
	const [iphone8, setiphone8] = useState(window.innerWidth < 376)
	const [isIphone12, setIphone12] = useState(window.innerWidth < 429 && window.innerWidth > 422)

	const updateMedia = () => {
		setSE(window.innerWidth < 321)
		set8Plus(window.innerWidth < 415 && window.innerWidth > 410)
		setIphone12(window.innerWidth < 429 && window.innerWidth > 422)
	}
	// useEffect(() => {
	//   window.addEventListener('resize', updateMedia)
	//   return () => window.removeEventListener('resize', updateMedia)
	// })

	useEffect(() => {
		// const document.getElementByClass('my-mobile-popup')
		// root-123
		// setTimeout(() => {
		// let el: any = document.querySelector('.MuiDialog-root.makeStyles-paper-39')
		//   if (el.scrollTop && el) {
		//     el.scrollTop = 0
		//   }
		// }, 3000)
		// el.scrollToTop = 0
		// document.body.scrollTop = 0 // For Safari
		// document.documentElement.scrollTop = 0 // For Chrome, Firefox, IE and Opera
		// let el2: any = document.getElementById('main-item-345')
		// el2.scrollIntoView({
		//   block: 'start',
		// })
		// FIRST SOLUTIONL:
		// let elm = document.querySelector('.MuiDialog-root')
		// elm.scrollTop = 0
	})

	return (
		<div className='my-mobile-popup-parent'>
			{/* {console.log("set8Plus", is8Plus)} */}
			{/* {console.log('inside dashboard popup',props)} */}
			{/* ye home page pr jo button dikh rha hai List and De-List , this is first button*/}
			<Button variant='outlined' onClick={handleClickOpen} className={buttonText == 'LIST' ? classes.clip : buttonText == 'DE-LIST' ? classes.declip : classes.clipSold} disabled={props.disabled}>
				{/* {console.log("props.label", props.label)} */}
				{/* {console.log('what is button text',buttonText)} */}
				{buttonText}
			</Button>

			{isMobileOnly ? (
				<div className='my-mobile-popup'>
					<GlobalCss />
					<Dialog
						onClick={(e) => {
							e.stopPropagation()
						}}
						fullScreen
						open={open}
						className={classes.paper}
						onClose={handleClose}
						// TransitionComponent={Transition}
						// ^^🏇︎🏇︎ Disabling the animation in the fullscreen dialog to solve the buggy initial transition reported by sam on friday.
						style={{
							// position: 'fixed', // original
							// overflowY: 'scroll', // ~sahil 🛑︎🛑︎🛑︎ debug, 🛑︎🛑︎🛑︎
							// position: 'relative', // ~sahil 🛑︎🛑︎🛑︎ debug, 🛑︎🛑︎🛑︎ (ned to make it relative fix somehow..)
							zIndex: 1300, // ~sahil 🛑︎🛑︎🛑︎ debug, 🛑︎🛑︎🛑︎
							// border: '20px solid blue', // ~sahil 🛑︎🛑︎🛑︎ debug, 🛑︎🛑︎🛑︎
							// left: is8Plus ? 0 : isMobile ? "" : "",
							// width: is8Plus ? '414px' : isMobileOnly ? '375px' : '60%',
							// maxHeight: isIphone12 ? '428px' : isMobile ? '812px' : '801px !important', // ~sahil 🛑︎🛑︎🛑︎ debug, 🛑︎🛑︎🛑︎
							justifyContent: 'center',
							alignItems: 'center',
							// marginLeft: isMobile ? '0px' : '',
							margin: isMobile ? '' : '0 auto',
							borderRadius: isMobile ? '20px 20px 0px 0px' : '12px',
							overflow: 'auto', // ~sahil : original
						}}
					>
						{/* 🔽︎🔽︎ Debugging scroll issue! */}
						{/* <div className='staticHeight' style={{height: '300px', color: 'deeppink'}}>
              Lorem ipsum dolor sit, amet consectetur adipisicing elit. Tempore, dolorem suscipit. Hic sit, a ex nesciunt odit deleniti natus labore officiis quae, quasi fugit
              voluptas unde ea molestias fugiat accusantium!
            </div> */}
						{/* teams */}
						<div
							id='root-123'
							style={{
								margin: 'auto auto', //~sahil, added this to vertically center contents of fullscreen dialog box.
								// border: '2px solid red',
								padding: '2% 3%',
								// position: 'relative', // ~sahil 🛑︎🛑︎🛑︎ debug, 🛑︎🛑︎🛑︎
								justifyContent: 'center',
								// width: isMobileOnly ? '375px' : '',
								// height: isMobile ? '812px' : '', //~sahil, original
								// height: '100%', //~sahil,🛑︎🛑︎🛑︎🛑︎🛑︎🛑︎ this is good, but with height 100vh, it works as intended for now..!
								// ^^~sahil, yo udon't need 100% height now, coz you are using margin auto on top and bottom, so it vertically centers the contents, yikes! 9 Jun, 2021.
								alignItems: 'center',
								textAlign: 'center',
								overflow: 'visible',
								// ^^ This ensures that fullscreen popup is scrollable vertically.
								// marginLeft: isIphone12 ? '24px' : is8Plus ? '15px' : isMobileOnly ? '0px' : '0%', //~sahil, giving such margins is poisonous.
								// marginTop: isMobile ? (iphone8 ? '0%' : 23) : '', //~sahil, giving such margins is poisonous.
								// position: 'absolute', // ~sahil, original
								// marginLeft: isMobile ? 10 : "",
							}}
						>
							{/* 🧡︎🧡︎🧡︎ */}
							{isSubmitted ? (
								<div
									className='loading-screen-listing-delisting'
									style={{
										// background: 'grey',
										position: 'absolute',
										width: '100vw',
										height: '100vh',
										top: 0,
										left: 0,
										zIndex: 5000,
										background: 'rgba(0, 0, 0, 0.9)',
									}}
								>
									<div
										style={{
											position: 'absolute',
											// border: '2px solid green',
										}}
									>
										<CircularProgress color='#A39C73' />
										{/* <Rolling /> */}
										{/* ^^ That didn't work. */}
										{/* <img
                      src={'/images/rolling.svg'}
                      // src={Rolling}
                      style={{
                        width: '19px',
                        height: '19px',
                        marginTop: '6px',
                      }}
                      alt='Shut elisnt'
                    /> */}

										<br />
										<div
											style={{
												// top: 20,
												padding: 20,
												color: '#BC936B',
												position: 'relative',
												fontSize: '1.7rem',
											}}
										>
											{`${buttonText.slice(0, 1).toUpperCase()}${buttonText.slice(1).toLowerCase()}ing`} your ticket...
										</div>
									</div>
								</div>
							) : (
								''
							)}

							<div
								id='main-item-345'
								aria-label='top-heading in dashboard popup screen'
								style={{
									// zIndex: 1300, // ~sahil 🛑︎🛑︎🛑︎ debug, 🛑︎🛑︎🛑︎
									// position: 'relative', // ~sahil 🛑︎🛑︎🛑︎ debug, 🛑︎🛑︎🛑︎
									// border: '2px solid blue', // ~sahil 🛑︎🛑︎🛑︎ debug, 🛑︎🛑︎🛑︎
									// height: '16px', // ~sahil, //~sahil, lost coal.
									fontSize: '24px',
									color: '#E7E1C5',
									justifyContent: 'center',
									alignItems: 'center',
									textAlign: 'center',
									marginBottom: isMobile ? '' : '20px',
								}}
							>
								{SeatStatus === 'NOT_LISTED' ? 'List Seat' : SeatStatus === 'LISTED' ? 'De-List Seat' : ' Sold seat'}
							</div>
							{/*  */}
							{/*  */}
							{/*  */}
							{isMobile ? (
								<div
									style={
										{
											// border: '2px solid blue',
										}
									}
								>
									<div
										className='d-flex flex-column justify-content-center align-items-center'
										style={{
											// position: 'relative', // ~sahil 🛑︎🛑︎🛑︎ debug, 🛑︎🛑︎🛑︎
											width: isTablet ? '1100px' : isMobile ? '' : '270px',
											// height: isMobile ? '130px' : '220px',//~sahil, lost coal.
											margin: '15px 0',
											marginTop: '50px',
										}}
									>
										{backgroundImage}

										<section className='my-simple-flex'>
											<div className='my-simple-flex--child'>
												<div className='title1'>{title_One.join(' ')}</div>
												<div className='knicks'>{title_Two}</div>
											</div>
											<div className='vs'>VS</div>
											<div className='my-simple-flex--child'>
												<div
													className='huston'
													style={{
														// In the case of TBD we need to check if the top-right name is there or not, if not we give vertical padding 0.
														padding: title_three.join(' ') ? '9px 0' : '0px',
													}}
												>
													{title_three.join(' ')}
												</div>
												<div className='rockets'>{title_four}</div>
											</div>
										</section>

										<br />

										<div
											className='d-flex justify-content-between'
											style={{
												// outline: '2px solid yellow',
												zIndex: 1,
												// background: ' rgba(196, 196, 196, 0.05)',
												width: '100%',
												padding: '2%',
												background: 'rgba(255, 255, 255, 0.08)',
												borderRadius: '0px 0px 6px 6px ',
												// height: '31px',
												// border: '2px solid yellow',
												// width: '100%', //~sahil
												// marginLeft: '170px', //~lost coal~sahil
												// marginTop: '47px',
											}}
										>
											<div className='popup-dates-1'>
												<Moment format='MMM D YYYY' withTitle>
													{props.details.event_date}
												</Moment>
											</div>
											<div
												className='popup-dates-1'
												style={
													{
														// border: '2px solid deeppink',//~sahil
													}
												}
											>
												<Moment format='ddd h:mm a' withTitle>
													{props.details.event_date}
												</Moment>
											</div>
										</div>
									</div>

									<div
										style={{
											zIndex: 1,
											position: 'relative',
											// ^^ Added position relative coz z-index only works with position other than static(default).
											// height: '49px',
											fontSize: isMobile ? '14px' : '18px',
											lineHeight: isMobile ? '31.5px' : '47.79px',
											color: '#DAD1BA',
											fontFamily: 'Atten New',
											fontStyle: 'normal',
											textAlign: 'center',
											// marginTop: isMobileOnly ? '35px' : '10px',
										}}
										className='d-flex justify-content-center align-item-center  '
									>
										<img
											alt='shut eslint3'
											src={Seats}
											style={{
												width: '19px',
												height: '19px',
												marginTop: '6px',
												paddingRight: '5px',
											}}
										/>
										<div>{props.details.section}&nbsp;&nbsp;</div>
										<div>Row {props.details.row},&nbsp;&nbsp;</div>
										<div>
											Seats &nbsp;{props.details.seats[0]}-{maxSeat}
										</div>
									</div>
								</div>
							) : (
								''
							)}
							{/* Choose your seats */}
							<div
								style={{
									zIndex: 1,
									position: 'relative',
								}}
								className='d-flex justify-content-center align-item-center '
							>
								<div>
									{' '}
									<Divider
										style={{
											width: isMobile ? '82px' : '254px',
											height: isMobile ? '0px' : '2px',
											borderBottom: '1px solid #BC936B',
											marginTop: '16px',
											marginRight: '5px',
										}}
									/>
								</div>
								<div
									style={{
										height: isMobile ? '32px' : '49px',
										fontSize: isMobile ? '18px' : '22px',
										lineHeight: isMobile ? '32px' : '47.79px',
										color: '#FFFFFF',
										fontFamily: 'Atten New',
										fontStyle: 'normal',
										textAlign: 'center',
										marginTop: isMobile ? '' : '-5px',
									}}
								>
									Choose your seats
								</div>
								<div>
									{' '}
									<Divider
										style={{
											width: isMobile ? '82px' : '254px',
											height: isMobile ? '0px' : '2px',
											borderBottom: '1px solid #BC936B',
											marginTop: '16px',
											marginLeft: '5px',
										}}
									/>
								</div>
							</div>
							<div
								className='d-flex flex-wrap justify-content-center align-item-center mt-1 '
								style={{
									zIndex: 1,
									position: 'relative',

									background: 'rgba(255, 255, 255, 0.08)',
									height: '65px',
									borderRadius: '6px',
									color: '#E7E1C5',
									display: 'flex',
									alignItems: 'center',
									textAlign: 'center',
									justifyContent: 'center',
								}}
							>
								Due to COVID policies, teams do not allow you to split seats for resale.
							</div>
							<div className='d-flex justify-content-center align-item-center mt-1' style={{}}>
								<div>
									<Divider
										style={{
											// width: isMobile ? '70px' : '230px',
											height: isMobile ? '0px' : '2px',
											borderBottom: '1px solid #BC936B',
											marginTop: '20px',
											marginRight: '5px',
										}}
									/>
								</div>
								<div
									style={{
										zIndex: 1,

										height: '49px',
										fontSize: isMobile ? '18px' : '22px',
										lineHeight: '47.79px',
										color: '#FFFFFF',
										fontFamily: 'Atten New',
										fontStyle: 'normal',
										textAlign: 'center',
										marginTop: isMobile ? '-3px' : '',
									}}
								>
									Payout range per seats
								</div>
								<div>
									{' '}
									<Divider
										style={{
											// width: isMobile ? '70px' : '230px',
											height: isMobile ? '0px' : '2px',
											borderBottom: '1px solid #BC936B',
											marginTop: '20px',
											marginLeft: '5px',
										}}
									/>
								</div>
							</div>
							{/* //payout */}
							<div
								style={{
									height: '49px',
									fontSize: '22px',
									lineHeight: '47.79px',
									color: '#DAD1BA',
									fontFamily: 'Atten New',
									fontStyle: 'normal',
									textAlign: 'center',
								}}
								className='d-flex flex-wrap justify-content-center align-item-center '
							>
								<div
									style={{
										flexGrow: isMobile ? 0.09 : 0.03,
										zIndex: 1,
									}}
								>
									${props.details.alert_price}
								</div>

								<div style={{marginTop: '13px'}}>
									<div
										style={{
											marginLeft: '-6px',
											marginBottom: '-15px',
										}}
									>
										{' '}
										<Divider
											orientation='vertical'
											flexItem
											style={{
												height: '15px',
												color: '#BC936B',
												background: '#BC936B',
												border: '2px solid #BC936B',

												// boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
											}}
										/>
									</div>
									<div>
										<Divider
											style={{
												width: isMobile ? '92px' : '194px',
												height: '4px',
												borderBottom: '3px solid #BC936B',
												marginBottom: '20px',
											}}
										/>
									</div>
									<div style={{marginRight: '-6px', marginTop: '-31px'}}>
										{' '}
										<Divider
											style={{
												height: '15px',
												color: '#BC936B',
												border: '2px solid #BC936B',
												float: 'right',
												background: '#BC936B',
											}}
											orientation='vertical'
											flexItem
										/>
									</div>
								</div>
								<div
									style={{
										zIndex: 1,

										flexGrow: isMobile ? 0.09 : 0.03,
									}}
								>
									${props.details.payout}
								</div>
							</div>
							<div
								style={{
									// width: isMobile ? '' : '360px',

									height: '49px',
									fontSize: '16px',
									lineHeight: '47.79px',
									color: '#BEBEBE',
									fontFamily: 'Atten New',
									fontStyle: 'normal',
									textAlign: 'center',
									background: 'rgba(255, 255, 255, 0.08)',
									borderRadius: '6px',
								}}
								className='d-flex justify-content-center align-item-center'
							>
								<div>Alert sent before payout is below ${props.details.alert_price}.</div>
							</div>
							<div className='d-flex justify-content-center align-item-center '>
								<div>
									{' '}
									<Divider
										style={{
											// width: isMobile ? '82px' : '240px',
											height: '2px',
											borderBottom: '1px solid #BC936B',
											marginTop: '20px',
											marginRight: '9px',
										}}
									/>
								</div>
								<div
									style={{
										height: isMobile ? '32px' : '49px',
										fontSize: isMobile ? '18px' : '22px',
										lineHeight: isMobile ? '32px' : '47.79px',
										color: '#FFFFFF',
										fontFamily: 'Atten New',
										fontStyle: 'normal',
										textAlign: 'center',
										marginTop: isMobile ? '6px' : '',
									}}
								>
									Special Instructions
								</div>
								<div>
									{' '}
									<Divider
										style={{
											// width: isMobile ? '82px' : '240px',
											height: '2px',
											borderBottom: '1px solid #BC936B',
											marginTop: '20px',
											marginLeft: '9px',
										}}
									/>
								</div>
							</div>
							<div>
								<Form.Group
									controlId='formBasicEmail'
									className='d-flex justify-content-center align-item-center mt-1'
									style={{
										width: isMobile ? '' : '360px',
										height: '44px',
										alignItems: 'center',
										textAlign: 'center',
										color: '#BEBEBE',
										fontFamily: 'Atten New',
										fontStyle: 'normal',
										justifyContent: 'center',
										borderRadius: '6px',

										// marginRight: isMobileOnly ? '20px' : '',//~sahil, purged this.
										// marginLeft: isMobileOnly ? '20px' : '',
									}}
								>
									<Form.Control
										style={{
											background: 'rgba(255, 255, 255, 0.08)',
											border: 'none',
											color: 'rgb(190, 190, 190)',
											textAlign: 'center',
										}}
										type='text'
										placeholder='Please let us know how we can help.'
									/>
								</Form.Group>
							</div>
							<div className='d-flex justify-content-center align-item-center ' style={{marginTop: '10%'}}>
								<DialogActions>
									<Button
										style={{
											background: 'rgba(182, 182, 182, 0.5)',
											width: '129px', //by sahil.
											// height: '40px',
											fontFamily: ' Atten New',
											fontStyle: ' normal',
											fontWeight: 'bold',
											borderRadius: '28px',
											color: '#FFFFFF',
											fontSize: '16px',
											// lineHeight: '48px',
											marginRight: isMobile ? '50px' : '',

											// paddingBottom: "10px",
										}}
										onClick={handleClose}
										variant='outlined'
									>
										Cancel
									</Button>
									<div className='flex-grow-2' />
									<Button
										value='confirm'
										disabled={isSubmitted /*Fixed the multple tab button fix @ date: 1 June, 21, Ankur*/}
										style={{
											width: '129px', // by sahil.
											// height: '40px',
											borderRadius: '28px',
											color: buttonText === 'DE-LIST' ? '#C57A1E' : '#FFFFFF',
											fontSize: '16px',
											// lineHeight: '48px',
											marginTop: '1px',
											background: buttonText === 'LIST' ? '#C57A1E' : '2px solid #C57A1E',
											fontFamily: ' Atten New',
											fontStyle: ' normal',
											fontWeight: 'bold',

											border: buttonText === 'DE-LIST' ? '2px solid #C57A1E' : '#C57A1E',
										}}
										onClick={(e) => handleConfirm(props)}
										variant='outlined'
										onChange={(e) => AlertDialog(props)}
										// className={props.className}
									>
										{/* ye hamra second button text hai Listed and De-listed */}

										{buttonText}
									</Button>
								</DialogActions>
							</div>
						</div>
					</Dialog>
				</div>
			) : (
				<Dialog open={open} TransitionComponent={Transition} keepMounted onClose={handleClose} aria-labelledby='alert-dialog-slide-title' aria-describedby='alert-dialog-slide-description' maxWidth='md'>
					<DialogTitle
						id='alert-dialog-slide-title'
						style={{
							padding: '0px',
						}}
					>
						<div
							style={{
								height: '16px',
								fontSize: '24px',
								color: '#E7E1C5',
								justifyContent: 'center',
								alignItems: 'center',
								textAlign: 'center',
								marginBottom: isMobile ? '' : '20px',
							}}
						>
							{SeatStatus == 'NOT_LISTED' ? 'List Seat' : SeatStatus == 'LISTED' ? 'De-List Seat' : ' Sold seat'}
						</div>
						<Row>
							{isMobileOnly ? (
								<Col>
									<div
										style={{
											// width: isTablet ? '1100px' : isMobile ? '' : '270px',
											height: isMobile ? '130px' : '220px',
											marginTop: '13%',
											marginLeft: '5%',
										}}
										className='d-flex justify-content-center align-items-center '
									>
										{props.details.league && props.details.league.includes('MLB') ? (
											<img
												alt='shut eslint'
												style={{
													// width: '339px',
													height: '189px',
													marginLeft: '170px',
												}}
												className='d-flex justify-content-center align-items-center '
												src={MLB}
											/>
										) : props.details.league && props.details.league.includes('NBA') ? (
											<img
												alt='shut eslint'
												style={{
													// width: '339px',
													height: '201px',
													// marginLeft: '170px',
												}}
												className='d-flex justify-content-center align-items-center '
												src={NBA}
											/>
										) : props.details.league && props.details.league.includes('NFL') ? (
											<img
												alt='shut eslint'
												style={{
													// width: '339px',
													height: '201px',
													// marginLeft: '170px',
												}}
												className='d-flex justify-content-center align-items-center '
												src={NFL}
											/>
										) : props.details.league && props.details.league.includes('NHL') ? (
											<img
												alt='shut eslint'
												style={{
													// width: '339px',
													height: '201px',
													// marginLeft: '170px',
												}}
												className='d-flex justify-content-center align-items-center '
												src={NHL}
											/>
										) : props.details.league && props.details.league.includes('MLS') ? (
											<img
												alt='shut eslint'
												style={{
													// width: '339px',
													height: '201px',
													// marginLeft: '170px',
												}}
												className='d-flex justify-content-center align-items-center '
												src={MLS}
											/>
										) : null}
										<div className={classes.newYork}>{title_One.join(' ')}</div>
										<div className={classes.knicks}>{title_Two}</div>
										<div className={classes.vs}>VS</div>
										<div className={classes.houston}> {title_three.join(' ')}</div>
										<div className={classes.rockets}>{title_four}</div>{' '}
										<div
											style={{
												background: ' rgba(196, 196, 196, 0.08)',
												// width: '337px',
												height: '31px',
												marginLeft: '170px',
												marginTop: '47px',
											}}
										>
											<div className={classes.date_left}>
												{' '}
												<Moment format='MMM D YYYY' withTitle>
													{props.details.event_date}
												</Moment>
											</div>
											<div
												className={classes.date_right}
												style={{
													marginTop: isMobile ? '5px' : '0px',
													marginLeft: isMobile ? '' : '178px',
												}}
											>
												<Moment format='ddd h:mm a' withTitle>
													{props.details.event_date}
												</Moment>
											</div>
										</div>
									</div>

									<Row
										style={{
											height: '49px',
											fontSize: isMobile ? '14px' : '18px',
											lineHeight: isMobile ? '31.5px' : '47.79px',
											color: '#DAD1BA',
											fontFamily: 'Atten New',
											fontStyle: 'normal',
											textAlign: 'center',
											marginLeft: '20%',
											// marginTop: isMobileOnly ? '35px' : '10px',
										}}
									>
										<img
											src={Seats}
											style={{
												// width: '19px',
												height: '19px',
												marginTop: '6px',
											}}
										/>{' '}
										&nbsp; &nbsp;
										<div>Section {props.details.section}&nbsp;&nbsp;</div>
										<div>Row {props.details.row},&nbsp;&nbsp;</div>
										<div>
											Seats &nbsp;{props.details.seats[0]}-{maxSeat}
										</div>
									</Row>
								</Col>
							) : (
								<Col
									style={{
										backgroundImage: props.details.league && props.details.league.includes('NBA') ? `url(${NBABIG})` : props.details.league && props.details.league.includes('MLB') ? `url(${MLBBIG})` : props.details.league && props.details.league.includes('NFL') ? `url(${NFLBIG})` : props.details.league && props.details.league.includes('NHL') ? `url(${NHLBIG})` : props.details.league && props.details.league.includes('MLS') ? `url(${MLSBIG})` : '',
										backgroundRepeat: 'no-repeat',
										height: '254.87px',
										backgroundPosition: 'center',
									}}
								>
									<Row
										style={{
											marginTop: '23%',
											marginLeft: '21%',
										}}
									>
										<Col>
											<div
												className={classes.newYork}
												style={{
													marginTop: '-60%',
												}}
											>
												{title_One.join(' ')}
											</div>
											<div className={classes.knicks}>{title_Two}</div>
										</Col>
										<Col>
											{' '}
											<div
												style={{
													flex: 1,
													flexDirection: 'column',
													marginTop: '-33%',
													marginLeft: '15px',
												}}
											></div>
											<div className={classes.vs}>vs</div>
										</Col>
										<Col>
											{' '}
											<div
												style={{
													flex: 1,
													flexDirection: 'column',
													float: 'left',
													marginTop: '-58%',
													marginLeft: '-58%',
												}}
											>
												<div className={classes.houston}> {title_three.join(' ')}</div>
												<div className={classes.rockets}>{title_four}</div>
											</div>
										</Col>
										<Row
											style={{
												background: ' rgba(196, 196, 196, 0.08)',
												// width: '124%',
												marginLeft: '-26%',
												marginTop: '-2%',
											}}
										>
											<Col
												style={{
													display: 'flex',
													marginLeft: '18%',
												}}
											>
												<div
													className={classes.date_left}
													// style={{ flexGrow: isMobile ? 0 : 0.2 }}
												>
													{' '}
													<Moment format='D MMM YYYY' withTitle>
														{props.details.event_date}
													</Moment>
												</div>
												<div
													style={{
														// height: '49px',
														fontSize: isMobile ? '14px' : '18px',
														lineHeight: isMobile ? '31.5px' : '47.79px',
														color: '#FFFFFF',
														fontFamily: 'Atten New',
														fontStyle: 'normal',
														textAlign: 'center',
														marginTop: '30px',
														flexGrow: isMobile ? 0 : 0.2,
													}}
													className='d-flex justify-content-center align-item-center mt-1 '
												>
													<img
														src={Seats}
														style={{
															// width: '19px',
															height: '19px',
															marginTop: '15px',
														}}
													/>{' '}
													&nbsp; &nbsp;
													<div>Section {props.details.section}&nbsp;&nbsp;</div>
													<div>Row {props.details.row},&nbsp;&nbsp;</div>
													<div>
														Seats &nbsp;{props.details.seats[0]}-{maxSeat}
													</div>
												</div>
												<div
													className={classes.date_right}
													// style={{ flexGrow: isMobile ? 0 : 0.2 }}
												>
													{' '}
													<Moment format='ddd h:mm a' withTitle>
														{props.details.event_date}
													</Moment>
												</div>
											</Col>
										</Row>
									</Row>
								</Col>
							)}
						</Row>
					</DialogTitle>
					<DialogContent
						style={{
							marginLeft: isMobileOnly ? '-5%' : '',
						}}
					>
						<DialogContentText id='alert-dialog-slide-description'>
							<div
								className='d-flex justify-content-center align-item-center '
								style={{
									marginTop: isMobileOnly ? '-4%' : '',
								}}
							>
								<div>
									{' '}
									<Divider
										style={{
											// width: isMobile ? '82px' : '254px',
											height: isMobile ? '0px' : '2px',
											borderBottom: '1px solid #BC936B',
											marginTop: '16px',
											marginRight: '5px',
										}}
									/>
								</div>
								<div
									style={{
										height: isMobile ? '32px' : '49px',
										fontSize: isMobile ? '18px' : '22px',
										lineHeight: isMobile ? '32px' : '47.79px',
										color: '#FFFFFF',
										fontFamily: 'Atten New',
										fontStyle: 'normal',
										textAlign: 'center',
										marginTop: isMobile ? '' : '-5px',
									}}
								>
									Choose your seats
								</div>
								<div>
									{' '}
									<Divider
										style={{
											// width: isMobile ? '82px' : '254px',
											height: isMobile ? '0px' : '2px',
											borderBottom: '1px solid #BC936B',
											marginTop: '16px',
											marginLeft: '5px',
										}}
									/>
								</div>
							</div>
							<div
								className='d-flex flex-wrap justify-content-center align-item-center mt-1 '
								style={{
									// width: isMobile ? '336px' : '681px',
									// marginLeft: isMobile ? '20px' : '12%',
									background: 'rgba(255, 255, 255, 0.08)',
									height: '65px',
									borderRadius: '6px',
									color: '#E7E1C5',
									display: 'flex',
									alignItems: 'center',
									textAlign: 'center',
									justifyContent: 'center',
								}}
							>
								Due to COVID policies, teams do not allow you to split seats for resale.
							</div>
							<div className='d-flex justify-content-center align-item-center mt-1'>
								<div>
									{' '}
									<Divider
										style={{
											// width: isMobile ? '70px' : '230px',
											height: isMobile ? '0px' : '2px',
											borderBottom: '1px solid #BC936B',
											marginTop: '20px',
											marginRight: '5px',
										}}
									/>
								</div>
								<div
									style={{
										height: '49px',
										fontSize: isMobile ? '18px' : '22px',
										lineHeight: '47.79px',
										color: '#FFFFFF',
										fontFamily: 'Atten New',
										fontStyle: 'normal',
										textAlign: 'center',
										marginTop: isMobile ? '-3px' : '',
									}}
								>
									Payout range per seats
								</div>
								<div>
									{' '}
									<Divider
										style={{
											// width: isMobile ? '70px' : '230px',
											height: isMobile ? '0px' : '2px',
											borderBottom: '1px solid #BC936B',
											marginTop: '20px',
											marginLeft: '5px',
										}}
									/>
								</div>
							</div>
							<div
								style={{
									height: '49px',
									fontSize: '22px',
									lineHeight: '47.79px',
									color: '#DAD1BA',
									fontFamily: 'Atten New',
									fontStyle: 'normal',
									textAlign: 'center',
								}}
								className='d-flex flex-wrap justify-content-center align-item-center '
							>
								<div style={{flexGrow: isMobile ? 0.09 : 0.03}}>${props.details.alert_price}</div>

								<div style={{marginTop: '13px'}}>
									<div
										style={{
											marginLeft: '-6px',
											marginBottom: '-15px',
										}}
									>
										{' '}
										<Divider
											orientation='vertical'
											flexItem
											style={{
												height: '15px',
												color: '#BC936B',
												background: '#BC936B',
												border: '2px solid #BC936B',

												// boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
											}}
										/>
									</div>
									<div>
										{' '}
										<Divider
											style={{
												// width: isMobile ? '92px' : '194px',
												height: '4px',
												borderBottom: '3px solid #BC936B',
												marginBottom: '20px',
											}}
										/>
									</div>
									<div style={{marginRight: '-6px', marginTop: '-31px'}}>
										{' '}
										<Divider
											style={{
												height: '15px',
												color: '#BC936B',
												border: '2px solid #BC936B',
												float: 'right',
												background: '#BC936B',
											}}
											orientation='vertical'
											flexItem
										/>
									</div>
								</div>
								<div style={{flexGrow: isMobile ? 0.09 : 0.03}}>${props.details.payout}</div>
							</div>
							<div
								style={{
									height: '49px',
									fontSize: '16px',
									lineHeight: '47.79px',
									color: '#BEBEBE',
									fontFamily: 'Atten New',
									fontStyle: 'normal',
									textAlign: 'center',
									background: 'rgba(255, 255, 255, 0.08)',
									borderRadius: '6px',
									marginLeft: isMobileOnly ? '20px' : '30%',
									marginRight: isMobileOnly ? '20px' : '31%',
								}}
								className='d-flex justify-content-center align-item-center'
							>
								<div>Alert sent before payout is below ${props.details.alert_price}.</div>
							</div>

							<div className='d-flex justify-content-center align-item-center '>
								<div>
									{' '}
									<Divider
										style={{
											// width: isMobile ? '82px' : '240px',
											height: '2px',
											borderBottom: '1px solid #BC936B',
											marginTop: '20px',
											marginRight: '9px',
										}}
									/>
								</div>
								<div
									style={{
										height: isMobile ? '32px' : '49px',
										fontSize: isMobile ? '18px' : '22px',
										lineHeight: isMobile ? '32px' : '47.79px',
										color: '#FFFFFF',
										fontFamily: 'Atten New',
										fontStyle: 'normal',
										textAlign: 'center',
										marginTop: isMobile ? '6px' : '',
									}}
								>
									Special Instructions
								</div>
								<div>
									{' '}
									<Divider
										style={{
											// width: isMobile ? '82px' : '240px',
											height: '2px',
											borderBottom: '1px solid #BC936B',
											marginTop: '20px',
											marginLeft: '9px',
										}}
									/>
								</div>
							</div>

							<div>
								<Form.Group
									controlId='formBasicEmail'
									className='d-flex justify-content-center align-item-center mt-1'
									style={{
										height: '44px',
										alignItems: 'center',
										textAlign: 'center',
										color: '#BEBEBE',
										fontFamily: 'Atten New',
										fontStyle: 'normal',
										justifyContent: 'center',
										borderRadius: '6px',

										marginRight: isMobileOnly ? '20px' : '31%',
										marginLeft: isMobileOnly ? '20px' : '30%',
									}}
								>
									<Form.Control
										style={{
											background: 'rgba(255, 255, 255, 0.08)',
											border: 'none',
											color: 'rgba(231, 225, 197, 1)',
											textAlign: 'center',
										}}
										type='text'
										placeholder='Please let us know how we can help.'
									/>
								</Form.Group>
							</div>
						</DialogContentText>
					</DialogContent>
					<DialogActions
						style={{
							justifyContent: 'center',
							display: 'flex',
							marginBottom: '2%',
							marginRight: '3%',
						}}
					>
						<Button
							style={{
								background: 'rgba(182, 182, 182, 0.5)',
								// width: '129px',
								// height: '40px',
								fontFamily: ' Atten New',
								fontStyle: ' normal',
								fontWeight: 'bold',
								borderRadius: '28px',
								color: '#FFFFFF',
								fontSize: '16px',
								// lineHeight: '48px',
								marginRight: isMobile ? '50px' : '',

								// paddingBottom: "10px",
							}}
							onClick={handleClose}
							variant='outlined'
						>
							Cancel
						</Button>
						<div className='flex-grow-2' />
						<Button
							value='confirm'
							style={{
								// width: '129px',
								// height: '40px',
								borderRadius: '28px',
								color: buttonText === 'DE-LIST' ? '#C57A1E' : '#FFFFFF',
								fontSize: '16px',
								// lineHeight: '48px',
								// marginTop: '1px',
								background: buttonText === 'LIST' ? '#C57A1E' : '2px solid #C57A1E',
								fontFamily: ' Atten New',
								fontStyle: ' normal',
								fontWeight: 'bold',

								border: buttonText === 'DE-LIST' ? '2px solid #C57A1E' : '#C57A1E',
							}}
							onClick={(e) => handleConfirm(props)}
							variant='outlined'
							onChange={(e) => AlertDialog(props)}
							// className={props.className}
						>
							{/* ye hamra second button text hai Listed and De-listed */}
							{buttonText}
						</Button>
					</DialogActions>
				</Dialog>
			)}
		</div>
	)
}
